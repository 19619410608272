import { AxiosInstance } from 'axios'
import {
  EventQuery,
  EventTypeQuery,
  EventTypeResponse,
  OccurrenceEvent,
} from 'services/event/types'
import { Result } from 'services/types'
import HttpClient from '../httpClient'

export interface EventDriver {
  queryEvents(query: EventQuery): Promise<Result<OccurrenceEvent>>
  queryEventTypes(query: EventTypeQuery): Promise<Result<EventTypeResponse>>
}

class EventDriverImpl implements EventDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async queryEvents(
    query: EventQuery,
  ): Promise<Result<OccurrenceEvent>> {
    const response = await this.httpClient.get<Result<OccurrenceEvent>>(
      '/event',
      { params: query },
    )

    return response.data
  }

  public async queryEventTypes(
    query?: EventTypeQuery,
  ): Promise<Result<EventTypeResponse>> {
    const response = await this.httpClient.get<Result<EventTypeResponse>>(
      '/eventType',
      { params: query },
    )

    return response.data
  }
}

export default new EventDriverImpl()
