import { FC, useEffect, useState } from 'react'
import { Button, Input, Modal } from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { CentralContactList } from 'services/central/types'

import styles from './styles.module.scss'
import { useGetContacts } from 'shared/hooks/services'
import { ContactQueryResponse } from 'services/contact/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useDebounce } from 'shared/hooks'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'

type AddContactModalProps = {
  onClose: () => void
  onSave: (contact: CentralContactList) => void
  selectedContact?: CentralContactList
  contacts?: CentralContactList[]
}

export const AddContactModal: FC<AddContactModalProps> = ({
  onSave,
  onClose,
  selectedContact,
  contacts,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [currentContact, setCurrentContact] = useState({
    id: '',
    name: '',
    code: '',
  })
  const [contactName, setContactName] = useState('')

  const customerId = localStorage.getItem('customerId') || ''

  const { contacts: contactsList, isFetchingContacts } = useGetContacts(
    {
      customerId,
      name: contactName,
    },
    !!customerId,
  )

  const codes = contacts?.map((contact) => contact.code.replace(/^0+/, ''))
  const contactIds = contacts?.map((contact) => contact.id)
  const handleFilter = useDebounce(setContactName)

  useEffect(() => {
    if (selectedContact) {
      setCurrentContact({
        id: selectedContact?.id || '',
        name: selectedContact?.name || '',
        code: selectedContact?.code || '',
      })
    }
  }, [selectedContact])

  const isCodeAlreadyExists = codes?.includes(
    currentContact.code.replace(/^0+/, ''),
  )

  useEffect(() => {
    if (location?.state?.contactName) {
      setContactName(location?.state?.contactName)
    }
  }, [location])

  return (
    <Modal isVisible onClose={onClose} title="Novo usuário">
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault()
          navigate({ state: '' })
          onSave(currentContact)
          setCurrentContact({
            id: '',
            name: '',
            code: '',
          })
        }}
      >
        <div className={styles.comboBoxWrapper}>
          <Combobox
            id="contactId"
            isLoading={isFetchingContacts}
            label={{ text: 'Contato' }}
            onSearch={handleFilter}
            items={parseDataToComboboxV2(
              contactsList
                ?.filter((contact) => !contactIds?.includes(contact.id))
                .sort((a, b) => a.name.localeCompare(b.name)) || [],
              'name',
            )}
            value={currentContact.name}
            disabled={!!selectedContact?.id}
            onChange={(selected) => {
              const contact = selected as ComboboxItem<ContactQueryResponse>

              setCurrentContact({
                id: contact.value.id,
                name: contact.value.name,
                code: '',
              })
            }}
          />
          <Button
            type="tertiary"
            buttonTitle="Criar contato"
            onClick={() =>
              navigate('/contact', {
                state: { background: location },
              })
            }
          />
          <Outlet />
        </div>
        <Input
          value={currentContact.code}
          label="Posição"
          name="code"
          id="code"
          type="number"
          onChange={(e) => {
            const value = e.target.value
            setCurrentContact((old) => ({
              ...old,
              code: value,
            }))
          }}
          errorMessage={
            isCodeAlreadyExists
              ? 'Não é possível cadastrar dois usuários na mesma posição'
              : ''
          }
        />
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Cancelar"
            onClick={() => {
              onClose()
              navigate({ state: '' })
            }}
            type="secondary"
          />
          <Button
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            htmlType="submit"
            disabled={isCodeAlreadyExists}
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}
