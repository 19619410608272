import { useContext, useMemo, useRef, useState } from 'react'

import {
  SERVICE_ORDER_TYPE_EN_PT,
  SERVICE_ORDER_STATUS_ENUM,
  ServiceOrderFragment,
} from 'services/serviceOrder/types'

import { ReactComponent as Validate } from 'assets/svg/validate.svg'
import { ReactComponent as Pause } from 'assets/svg/pause.svg'
import { ReactComponent as Exclamation } from 'assets/svg/exclamation.svg'
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg'
import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'
import { ReactComponent as Play } from 'assets/svg/play.svg'
import { ReactComponent as Paper } from 'assets/svg/paper.svg'
import { ReactComponent as PictureIcon } from 'assets/svg/picture.svg'

import { Button, Icon, ProtectedImage, Tooltip } from 'components'

import { UserInfoContext, useToggle } from 'shared/hooks'
import { ModalImageView } from 'components/ModalImageView/ModalImageView'
import { CustomerPaths } from 'routes/constants/paths/customer'
import { usePersistentTab } from 'domains/occurrence/hooks'

import { ExecuteServiceOrderModal } from 'domains/serviceOrders/components/ExecuteServiceOrderModal/ExecuteServiceOrderModal'
import { useNavigate } from 'react-router'
import { ServiceOrderNoteModal } from 'domains/serviceOrders/components/ServiceOrderNoteModal/ServiceOrderNoteModal'
import { useFormContext } from 'react-hook-form'
import { UpdateServiceOrderForm } from 'domains/serviceOrders/schemas'
import { formatServiceOrderScheduleDate } from 'domains/serviceOrders/utilities'

import styles from './GeneralInfoCard.module.scss'
import { TagsModal } from 'domains/serviceOrders/components/TagsModal/TagsModal'
import ImageUploader from 'domains/serviceOrders/screens/ServiceOrderInfo/components/central/components/ImageUploader'

interface GeneralInfoCardProps {
  serviceOrderId: string
  serviceOrder: ServiceOrderFragment
  onSubmit: () => void
  onPressGallery?: () => void
}

export const GeneralInfoCard = ({
  serviceOrderId,
  serviceOrder,
  onSubmit,
  onPressGallery,
}: GeneralInfoCardProps) => {
  const [modalNoteProps, setModalNoteProps] = useState({
    title: '',
    submitButtonTitle: '',
    placeholder: '',
  })

  const { userInfo } = useContext(UserInfoContext)

  const { setValue, watch } = useFormContext<UpdateServiceOrderForm>()

  const modalImageView = useToggle()
  const serviceOrderNoteModal = useToggle()
  const executeServiceOrderModal = useToggle()
  const tagsModal = useToggle()
  const tooltip = useToggle()
  const buttonRef = useRef<HTMLDivElement>(null)
  const toggleImageUploader = useToggle()

  const { replaceUrl } = usePersistentTab('service-order-account-tab')
  const navigate = useNavigate()

  const shouldDisableButton: boolean = useMemo(() => {
    const serviceOrderStatusThatShouldDisableButton = [
      SERVICE_ORDER_STATUS_ENUM.CANCELED,
      SERVICE_ORDER_STATUS_ENUM.FINISHED,
      SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION,
    ]

    return serviceOrderStatusThatShouldDisableButton.includes(
      serviceOrder.status,
    )
  }, [serviceOrder])

  const validStatusForEditServiceOrder: boolean = useMemo(() => {
    const validStatusForEditServiceOrder = [
      SERVICE_ORDER_STATUS_ENUM.OPEN,
      SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
    ]

    return validStatusForEditServiceOrder.includes(serviceOrder.status)
  }, [serviceOrder])

  const onInspectAccount = () => {
    localStorage.setItem('accountId', serviceOrder.account.id || '')
    localStorage.setItem('accountCode', serviceOrder?.account?.code || '')

    if (serviceOrder?.account && serviceOrder.patrimony) {
      localStorage.setItem(
        'aggregatedAccountName',
        `${serviceOrder.account.code} - ${serviceOrder.patrimony.name}`,
      )
    }

    localStorage.setItem('patrimonyId', serviceOrder?.patrimony?.id || '')
    localStorage.setItem('customerId', serviceOrder?.customer?.id || '')
    localStorage.setItem('customerName', serviceOrder?.customer?.name || '')
    localStorage.setItem('centralId', serviceOrder?.account?.centralId || '')

    replaceUrl(`${CustomerPaths.Account}`)
  }

  return (
    <>
      <ImageUploader
        isVisible={toggleImageUploader.isVisible}
        onClose={() => toggleImageUploader.hide()}
        serviceOrder={{
          accountId: serviceOrder.account.id,
          customerId: serviceOrder.customer.id,
          serviceOrderId,
        }}
      />
      <TagsModal
        isVisible={tagsModal.isVisible}
        onClose={tagsModal.hide}
        tags={serviceOrder.tags}
      />
      <ExecuteServiceOrderModal
        isVisible={executeServiceOrderModal.isVisible}
        onClose={executeServiceOrderModal.hide}
        onClick={() => {
          setValue(
            'note',
            `Atendimento em execução por ${
              userInfo?.name || 'operador'
            } via sistema web.`,
          )

          executeServiceOrderModal.hide()
          onSubmit()
        }}
      />
      <ServiceOrderNoteModal
        isFinishServiceOrder={
          watch('status') === SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION
        }
        modalProps={modalNoteProps}
        isVisible={serviceOrderNoteModal.isVisible}
        onClose={() => {
          serviceOrderNoteModal.hide()
        }}
        onSave={(note, labels) => {
          setValue('note', note)
          setValue('labels', labels)

          serviceOrderNoteModal.hide()
          onSubmit()
        }}
      />
      <div className={styles.generalInfo}>
        <h1 className={styles.title}>Informações gerais</h1>
        <section className={styles.infoItems}>
          <div className={styles.wrapper}>
            <div className={styles.items}>
              <div className={styles.accountItem}>
                <small>Conta</small>
                <button
                  className={styles.viewMoreButton}
                  onClick={onInspectAccount}
                >
                  <LinkIcon width={8} height={8} /> Ver Mais
                </button>
              </div>
              <span>
                {serviceOrder.account.code} - {serviceOrder.customer.name}
              </span>
            </div>
            <div className={styles.items}>
              <small>Patrimônio</small>
              <span>{serviceOrder.patrimony.name}</span>
            </div>
            <div className={styles.items}>
              <small>Endereço</small>
              <span>
                {serviceOrder.patrimony?.address?.street},
                {serviceOrder.patrimony?.address?.number} -
                {serviceOrder.patrimony?.address?.district},
                {serviceOrder.patrimony?.address?.city},
                {serviceOrder.patrimony?.address?.acronym}
              </span>
            </div>
            <div className={styles.items}>
              <small>OS Núm.</small>
              <span>{serviceOrder.number}</span>
            </div>
            <div className={styles.items}>
              <small>Tipo</small>
              <span>{SERVICE_ORDER_TYPE_EN_PT[serviceOrder.type]}</span>
              <span>{serviceOrder.account.serviceType?.name}</span>
            </div>
            <div className={styles.items}>
              <small>Técnico</small>
              <span>{serviceOrder.technician?.name || '-'}</span>
            </div>
            <div className={styles.tagButtonWrapper}>
              {onPressGallery && (
                <>
                  <Button
                    icon={PictureIcon}
                    buttonTitle="Galeria"
                    type="tertiary"
                    className={styles.galleryBtn}
                    onClick={onPressGallery}
                  />
                  <Button
                    width="100px"
                    buttonTitle="Adicionar foto"
                    type="tertiary"
                    className={styles.imageUploader}
                    onClick={() => toggleImageUploader.show()}
                  >
                    <Icon name="gallery-add" />
                  </Button>
                </>
              )}
              <Button
                buttonTitle="Ver lista de tags"
                type="tertiary"
                icon={Paper}
                className={styles.tagButton}
                disabled={!serviceOrder.tags?.length}
                onClick={tagsModal.show}
              />
            </div>
            <div className={styles.items}>
              <small>Data Agendada</small>
              <span>
                {serviceOrder?.schedule
                  ? formatServiceOrderScheduleDate({
                      scheduleDate: serviceOrder.schedule?.date,
                      schedulePeriod: serviceOrder.schedule?.period,
                    })
                  : '-'}
              </span>
            </div>
          </div>
        </section>

        {serviceOrder.patrimony.imageId ? (
          <>
            <section
              className={styles.sectionImage}
              data-testid="modal-image-view"
            >
              <ProtectedImage
                height={130}
                className={styles.imagePatrimony}
                imageId={serviceOrder.patrimony.imageId}
                alt="patrimônio"
              />
              <button
                className={styles.hoverImage}
                onClick={() => modalImageView.show()}
                aria-label="button-modal"
              />
            </section>

            <ModalImageView
              isVisible={modalImageView.isVisible}
              onClose={() => modalImageView.hide()}
              imageIds={[serviceOrder.patrimony.imageId]}
            />
          </>
        ) : (
          <section className={styles.emptyState}>
            <Exclamation />
            <span data-testid="empty-state">
              Não há foto da fachada do patrimônio.
            </span>
          </section>
        )}
        <div className={styles.buttonWrapper}>
          {serviceOrder.status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS ? (
            <>
              <Button
                buttonTitle={'Pausar'}
                type="secondary"
                width="143px"
                icon={Pause}
                onClick={() => {
                  setValue('status', SERVICE_ORDER_STATUS_ENUM.PAUSED)
                  setModalNoteProps({
                    title: 'Pausar execução de ordem de serviço?',
                    placeholder: 'Informe o motivo da pausa.',
                    submitButtonTitle: 'PAUSAR',
                  })

                  serviceOrderNoteModal.show()
                }}
              />
              <Button
                icon={Validate}
                buttonTitle={'Concluir'}
                className={styles.primaryButton}
                type="primary"
                width="143px"
                onClick={() => {
                  setValue('status', SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION)
                  setModalNoteProps({
                    title:
                      'Deseja concluir a ordem de serviço e enviá-la para validação?',
                    placeholder: 'Descreva o serviço realizado.',
                    submitButtonTitle: 'CONCLUIR',
                  })

                  serviceOrderNoteModal.show()
                }}
              />
            </>
          ) : (
            <>
              <div
                ref={buttonRef}
                onPointerEnter={tooltip.show}
                onPointerLeave={tooltip.hide}
                data-testid="edit-button-tooltip"
              >
                <Button
                  buttonTitle={'Editar'}
                  type="secondary"
                  width="143px"
                  icon={EditIcon}
                  disabled={
                    shouldDisableButton || !validStatusForEditServiceOrder
                  }
                  onClick={() => {
                    navigate(`/so/edit/${serviceOrderId}`, {
                      state: { shouldReturnToSOInfo: true },
                    })
                  }}
                />

                <Tooltip
                  isVisible={
                    tooltip.isVisible && !validStatusForEditServiceOrder
                  }
                  type="informative"
                  parentRef={buttonRef}
                >
                  <p aria-label="tooltip-message">
                    {`A ordem de serviço não pode ser editada neste status. Apenas os status 'Aberta' e 'Agendada' permitem a edição.`}
                  </p>
                </Tooltip>
              </div>

              <Button
                icon={Play}
                className={styles.primaryButton}
                buttonTitle={'Executar'}
                type="primary"
                width="143px"
                onClick={() => {
                  setValue('status', SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS)
                  executeServiceOrderModal.show()
                }}
                disabled={shouldDisableButton}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
