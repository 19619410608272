import { useInfiniteQuery } from '@tanstack/react-query'

import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'

import {
  ActionPlanUpdateForm,
  PartitionContact,
  PartitionFindByIdResponse,
  PartitionQueryRequest,
  PartitionResponse,
  PartitionUpdatePayload,
} from './types'
import { Result } from '../types'
import HttpClient from '../httpClient'
import { endpoints } from './endpoints'

export interface PartitionDriver {
  queryPartitions(
    query: PartitionQueryRequest,
  ): Promise<Result<PartitionResponse>>
  updatePartition(id: string, partition: PartitionUpdatePayload): Promise<void>
  getContactPartitions(partitionId: string): Promise<Result<PartitionContact>>
  findById(id: string): Promise<PartitionFindByIdResponse>
}

export class PartitionDriverImpl implements PartitionDriver {
  constructor(private readonly httpClient = HttpClient) {}

  public async queryPartitions(
    query: PartitionQueryRequest,
  ): Promise<Result<PartitionResponse>> {
    const result = await this.httpClient.get<Result<PartitionResponse>>(
      '/partition/query',
      { params: query },
    )

    return result.data
  }

  public async updatePartition(
    id: string,
    partition: PartitionUpdatePayload,
  ): Promise<void> {
    return await this.httpClient.put(`/partition/${id}/update`, partition)
  }

  public async getContactPartitions(
    partitionId: string,
  ): Promise<Result<PartitionContact>> {
    const result = await this.httpClient.get<Result<PartitionContact>>(
      `/partition/${partitionId}/contact`,
    )
    return result.data
  }

  public async findById(id: string): Promise<PartitionFindByIdResponse> {
    const result = await this.httpClient.get<PartitionFindByIdResponse>(
      `/partition/${id}`,
    )

    return result.data
  }

  public async setPartitionContacts(
    id: string,
    data: ActionPlanUpdateForm,
  ): Promise<void> {
    return await this.httpClient.post(
      `/partition/${id}/action-plan/contacts`,
      data,
    )
  }
}

export default new PartitionDriverImpl()

const getPartitions = async (accountIds = '', code = '', offset = 0) => {
  const response = await HttpClient.get<Result<PartitionResponse>>(
    endpoints.query,
    {
      params: { accountIds, offset, ...(code && { code }) },
    },
  )

  const { data, totalElements } = response.data

  return {
    data: data.map((item) => ({
      ...item,
      aggregatedName: `${item.code} - ${item.name}`,
    })),
    totalElements,
  }
}

interface UseGetPartitions {
  accountIds?: string
  code?: string
}

export const useGetPartitions = ({ accountIds, code }: UseGetPartitions) => {
  const infiniteQuery = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['account-partitions', accountIds, code],
    queryFn: ({ pageParam }) => getPartitions(accountIds, code, pageParam),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<PartitionResponse>(lastPage, allPages),
    refetchOnWindowFocus: false,
    enabled: !!accountIds,
  })

  return { ...infiniteQuery, data: flattenPages(infiniteQuery.data) }
}
