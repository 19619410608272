import { useCallback, useEffect, useMemo, useState } from 'react'

import { DropDown } from 'domains/occurrence/components'
import { OccurrenceCard } from 'domains/occurrence/components/OccurrenceCard/OccurrenceCard'
import { OccurrenceCardActions } from 'domains/occurrence/types'

import styles from './OccurrenceManagementColumn.module.scss'
import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'

import {
  OccurrenceColumnTitle,
  OccurrenceListByCoverage,
  Filter,
} from 'services/occurrence/types'
import { Checkbox } from 'components'
import {
  filterOccurrences,
  getColumnFilters,
} from 'domains/occurrence/components/OccurrenceManagementColumn/utilities/filters'

type OccurrenceManagementColumnProps = {
  title: OccurrenceColumnTitle
  occurrences: OccurrenceListByCoverage[]
  onSelected: (id: string) => void
  selectedOccurrenceId: string
  actions: OccurrenceCardActions[]
}

export const OccurrenceManagementColumn = ({
  title,
  occurrences,
  onSelected,
  selectedOccurrenceId,
  actions,
}: OccurrenceManagementColumnProps) => {
  const [visibleOccurrences, setVisibleOccurrences] =
    useState<OccurrenceListByCoverage[]>(occurrences)

  const columnFilters = useMemo(
    () => getColumnFilters(occurrences),
    [occurrences],
  )

  const [selectedFilters, setSelectedFilters] =
    useState<Filter[]>(columnFilters)

  const filtersAmount = useMemo(
    () => selectedFilters.filter((filter) => filter.value).length,
    [selectedFilters],
  )

  const allFiltersSelected = useMemo(
    () => selectedFilters.every((filter) => filter.value),
    [selectedFilters],
  )

  const checkFilter = useCallback(
    (label: string) => {
      if (!selectedFilters.length) {
        return false
      }

      const selectedFilterIndex = selectedFilters.find(
        (filter) => filter.label === label,
      )

      return selectedFilterIndex?.value || false
    },
    [selectedFilters],
  )

  const handleCheckboxChange = useCallback(
    (label: string, checked: boolean) => {
      const updatedFilters = selectedFilters.map((filter) => {
        if (filter.label === label) {
          return {
            label,
            value: checked,
          }
        }

        return filter
      })

      setSelectedFilters(updatedFilters)

      const checkedFilters = updatedFilters.filter((filter) => filter.value)

      const filteredOccurrences = filterOccurrences(checkedFilters, occurrences)
      setVisibleOccurrences(filteredOccurrences)
    },
    [selectedFilters, occurrences],
  )

  // necessário para manter  as ocorrências filtradas em tela durante os pollings
  useEffect(() => {
    const filteredOccurrences = filterOccurrences(
      selectedFilters.filter((filter) => filter.value),
      occurrences,
    )

    setVisibleOccurrences(filteredOccurrences)
  }, [occurrences, selectedFilters])

  // necessário para identificar quando é renderizado ou removido um novo filtro
  useEffect(() => {
    const visibleFiltersLabel = columnFilters.map((filters) => filters.label)
    const selectedFiltersLabel = selectedFilters.map((filters) => filters.label)

    const newFilters = columnFilters.filter(
      (columnFilter) => !selectedFiltersLabel.includes(columnFilter.label),
    )

    setSelectedFilters((prev) => [
      ...prev.filter((selectedFilter) =>
        visibleFiltersLabel.includes(selectedFilter.label),
      ),
      ...newFilters?.map(({ label }) => ({
        label,
        value: allFiltersSelected,
      })),
    ])
  }, [columnFilters])
  // necessário desabilitar lint pois esta lógica só deve executar quando identificado que os filtros da coluna mudaram

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {title} • {occurrences?.length}
        {!!columnFilters.length && (
          <DropDown
            trigger={
              <div className={styles.options}>
                {!allFiltersSelected && filtersAmount}
                <FiltersIcon aria-label="column-filter" />
              </div>
            }
          >
            <div className={styles.filterAll}>
              <Checkbox
                id={'select-all'}
                label={'Selecionar todos'}
                checked={allFiltersSelected}
                small
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedFilters((prev) =>
                      prev.map((filter) => ({
                        ...filter,
                        value: true,
                      })),
                    )

                    setVisibleOccurrences(occurrences)
                  } else {
                    setSelectedFilters((prev) =>
                      prev.map((filter) => ({
                        ...filter,
                        value: false,
                      })),
                    )

                    setVisibleOccurrences([])
                  }
                }}
              />
            </div>

            <div className={styles.filter}>
              {columnFilters.map(({ label }) => (
                <div key={label}>
                  <Checkbox
                    id={label}
                    label={label}
                    checked={checkFilter(label)}
                    small
                    onChange={(e) => {
                      handleCheckboxChange(label, e.target.checked)
                    }}
                  />
                </div>
              ))}
            </div>
          </DropDown>
        )}
      </div>
      <div className={styles.content}>
        {visibleOccurrences?.length
          ? visibleOccurrences.map((occurrence) => (
              <OccurrenceCard
                stateName={occurrence.stateName}
                key={occurrence.id}
                onSelected={(id) => onSelected(id)}
                id={occurrence.id}
                number={occurrence.number}
                customer={occurrence?.customer}
                tactical={occurrence.tactical}
                alerts={occurrence.alerts}
                account={occurrence?.account}
                totalEvents={occurrence.totalEvents}
                typeName={occurrence.type.name}
                title={occurrence.title}
                actions={actions}
                columnTitle={title}
                patrimony={occurrence.patrimony}
                createdAt={occurrence.createdAt}
                finishedAt={occurrence?.finishedAt}
                operator={occurrence?.operator}
                selectedOccurrenceId={selectedOccurrenceId || ''}
              />
            ))
          : null}
      </div>
    </div>
  )
}
