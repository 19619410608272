import { ComponentProps, forwardRef } from 'react'
import styles from './styles.module.scss'

type ButtonSize = 'md' | 'sm' | 'lg'

export interface ButtonProps extends ComponentProps<'button'> {
  size?: ButtonSize
}

export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, type = 'button', size = 'md', ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        type={type}
        className={[styles.button, size && styles[size], className].join(' ')}
      />
    )
  },
)

IconButton.displayName = 'Button'

export default IconButton
