import { joiResolver } from '@hookform/resolvers/joi'
import {
  TableRef,
  ServiceOrderFilters,
  TableV2 as Table,
  Popover,
  Droplist,
  Icon,
  PaginationV2 as Pagination,
  EmptyState,
  LoaderV2 as Loader,
} from 'components'
import {
  filterSchema,
  ServiceOrderFiltersSchema,
} from 'components/ServiceOrderFilters/schemas/searchServiceOrderSchema'
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { CancelServiceOrder } from 'domains/serviceOrders/components/CancelServiceOrder/CancelServiceOrder'
import { ScheduleServiceOrderModal } from 'domains/serviceOrders/components/ScheduleServiceOrderModal'

import {
  ServiceOrderAggregatedQueryResponse,
  ServiceOrderDriver,
  SERVICE_ORDER_TYPE_EN_PT,
  SERVICE_ORDER_STATUS_ENUM,
  useGetServiceOrderHistory,
  SERVICE_ORDER_TYPE_ENUM,
} from 'services/serviceOrder'
import { usePutAndHandleServiceOrder } from 'domains/serviceOrders/hooks/services/usePutAndHandleServiceOrder'

import {
  ToggleHook,
  useToast,
  useToggle,
  useUserInfo,
  useGetServiceOrderInfo,
} from 'shared/hooks'
import { usePersistentTab } from 'domains/occurrence/hooks'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'

import {
  handleStatusLabel,
  isServiceOrderCancelable,
  isServiceOrderReschedulable,
  isServiceOrderSchedulable,
  isServiceOrderVisible,
} from 'domains/serviceOrders/utilities'

import { UbideskPermissions } from 'routes/types'
import {
  ServiceOrderPayload,
  ServiceOrderSchedule,
} from 'domains/serviceOrders/types'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { RichTooltip } from 'domains/serviceOrders/screens/components'
import { maskedDateTime } from 'utilities/date'
import styles from './styles.module.scss'

type ServiceOrderHistoryProps = {
  filtersProps?: ToggleHook
  filteredAccounts?: string[]
  openInNewTab?: boolean
}

const emptyServiceOrder: ServiceOrderAggregatedQueryResponse = {
  id: '',
  number: 0,
  account: { id: '', code: '' },
  customer: { id: '', name: '' },
  technician: { id: undefined, name: '' },
  contact: { id: '', name: '', countryCode: 0, provinceCode: 0, number: 0 },
  patrimony: { id: '', name: '' },
  status: SERVICE_ORDER_STATUS_ENUM.OPEN,
  scheduleDate: 0,
  schedulePeriod: 'AFTERNOON',
  type: SERVICE_ORDER_TYPE_ENUM.INSTALL,
  createdAt: 0,
  updatedAt: 0,
  labels: [],
}

const columnHelper = createColumnHelper<ServiceOrderAggregatedQueryResponse>()

export const ServiceOrderHistory = ({
  filtersProps,
  filteredAccounts,
  openInNewTab,
}: ServiceOrderHistoryProps) => {
  const { userInfo } = useUserInfo()
  const [serviceOrderIdToVizualize, setServiceOrderIdToVizualize] =
    useState<string>('')
  const [selectedServiceOrder, setSelectedServiceOrder] =
    useState<ServiceOrderAggregatedQueryResponse>()
  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState({ data: [], totalElements: 0 })

  const { mutatePutServiceOrder } = usePutAndHandleServiceOrder({
    messages: {
      success: 'Ordem de serviço reagendada com sucesso.',
      error: 'Não foi possível reagendar a ordem de serviço. Tente novamente.',
    },
  })

  const loader = useToggle()

  const { getFilters, setFilters } =
    usePersistentFilters<ServiceOrderFiltersSchema>('serviceOrder')

  const tableRef = useRef<TableRef>(null)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { replaceUrl } = usePersistentTab('view-service-order')

  const cancelServiceOrderComponent = useToggle()
  const scheduleServiceOrderComponent = useToggle()
  const [serviceOrderId, setServiceOrderId] = useState('')
  const [serviceOrderSchedule, setServiceOrderSchedule] =
    useState<ServiceOrderSchedule>()

  const form = useForm<ServiceOrderFiltersSchema>({
    resolver: joiResolver(filterSchema),
    defaultValues: getFilters(),
  })

  const { watch, register, unregister, getValues } = form

  const serviceOrderTypes = watch('type')
  const technicians = watch('technicians')
  const status = watch('status')
  const createdAtFrom = watch('createdAtFrom')
  const createdAtTo = watch('createdAtTo')
  const scheduleDateFrom = watch('scheduleDateFrom')
  const scheduleDateTo = watch('scheduleDateTo')
  const closedAtFrom = watch('closedAtFrom')
  const closedAtTo = watch('closedAtTo')

  const fetchServiceOrders = useCallback(async () => {
    if (!filteredAccounts?.length) return

    setIsFetching(true)

    const page = Number(searchParams.get('page')) || 1
    const offset = (page - 1) * 15

    try {
      const serviceOrders = await ServiceOrderDriver.query({
        offset,
        recordsPerPage: 15,
        ...(technicians && {
          technicianIds: technicians.map((technician) => technician.id),
        }),
        ...(createdAtFrom && { createdAtFrom }),
        ...(createdAtTo && { createdAtTo }),
        ...(scheduleDateFrom && { scheduleDateFrom }),
        ...(scheduleDateTo && { scheduleDateTo }),
        ...(closedAtFrom && { closedAtFrom }),
        ...(closedAtTo && { closedAtTo }),
        ...(status && { status }),
        ...(serviceOrderTypes && { serviceOrderTypes }),
        ...(filteredAccounts && { accountIds: filteredAccounts }),
        hasTechnician: watch('hasTechnician'),
        ...(!!watch('serviceType') && {
          serviceTypeId: watch('serviceType').id,
        }),
        ...(!!watch('accountTag') && {
          accountTagId: watch('accountTag').id,
        }),
      })
      setData(serviceOrders)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [
    loader,
    technicians,
    createdAtFrom,
    createdAtTo,
    scheduleDateFrom,
    scheduleDateTo,
    closedAtFrom,
    closedAtTo,
    status,
    serviceOrderTypes,
    filteredAccounts,
    watch,
  ])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchServiceOrders()
    }
  }, [page, filter])

  const { data: serviceOrderHistory } =
    useGetServiceOrderHistory(serviceOrderId)

  const hasInProgress = serviceOrderHistory?.some(
    (historyEntry) =>
      historyEntry.status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
  )

  const actions = useMemo(
    () => [
      {
        label: 'Visualizar',
        Icon: 'view',
        handler: (serviceOrder) => {
          setServiceOrderId(serviceOrder.id)
          setServiceOrderIdToVizualize(serviceOrder.id)
        },
      },
      {
        label: 'Editar',
        Icon: 'edit',
        handler: (item) => {
          replaceUrl(`/so/edit/${item.id}`)
        },
        isVisible: (item) => isServiceOrderVisible(item.status),
      },
      {
        label: 'Agendar',
        Icon: 'calendar',
        handler: (serviceOrder) => {
          setServiceOrderSchedule(serviceOrder)
          scheduleServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderSchedulable(item.status),
      },
      {
        label: 'Reagendar',
        Icon: 'calendar',
        handler: (serviceOrder) => {
          setServiceOrderSchedule(serviceOrder)
          scheduleServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderReschedulable(item.status),
      },
      {
        label: 'Cancelar',
        Icon: 'close-lg',
        handler: (item) => {
          setSelectedServiceOrder(item)
          cancelServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderCancelable(item.status),
        permission: {
          name: hasInProgress
            ? UbideskPermissions.CANCEL_EXECUTED_SO
            : undefined,
          message: 'Não é possível cancelar esta OS, pois já possui execuções.',
        },
      },
    ],
    [],
  )

  const toast = useToast()

  const { data: serviceOrderInfo, status: osStatus } = useGetServiceOrderInfo(
    serviceOrderIdToVizualize || '',
    Boolean(serviceOrderIdToVizualize),
    false,
  )

  useEffect(() => {
    if (osStatus === 'success') {
      const url = `/so/info/${serviceOrderInfo?.id}`
      if (openInNewTab) {
        window.open(url, '_blank')
      } else {
        navigate(url, {
          state: data,
        })
      }
    }

    if (osStatus === 'error') {
      toast.addToast({
        type: 'alert',
        message:
          'Não foi possível recuperar as informações da ordem de serviço. Tente novamente',
      })
    }
  }, [osStatus])

  const validateFilters = () => {
    const excludedKeys = [
      'number',
      'createdAtTo',
      'scheduleDateTo',
      'closedAtTo',
    ]
    const formKeys = Object.keys(getValues())
    formKeys.filter((key) => !excludedKeys.includes(key))

    if (createdAtFrom && !createdAtTo) return true
    if (scheduleDateFrom && !scheduleDateTo) return true
    if (closedAtFrom && !closedAtTo) return true

    return false
  }

  const handleScheduleServiceOrder = (data: ServiceOrderPayload) => {
    const {
      id,
      status,
      note,
      technician,
      contact,
      scheduleDate,
      schedulePeriod,
      tags,
      account,
      type,
      labels,
    } = data

    const userId = userInfo?.id

    mutatePutServiceOrder(
      {
        id,
        status,
        note,
        technicianId: technician?.id,
        userId,
        contact,
        scheduleDate,
        accountId: account?.id || '',
        serviceOrderType: type,
        ...(schedulePeriod && { schedulePeriod }),
        ...(tags?.length && {
          tags: tags.map((tag) => ({ name: tag.name })),
        }),
        labels,
      },
      {
        onSuccess: () => {
          fetchServiceOrders()
        },
      },
    )
  }

  useEffect(() => {
    register('technicians')
    register('type')
    register('status')
    register('createdAtFrom')
    register('createdAtTo')
    register('scheduleDateFrom')
    register('scheduleDateTo')
    register('closedAtFrom')
    register('closedAtTo')

    return () => {
      unregister('technicians')
      unregister('type')
      unregister('status')
      unregister('createdAtFrom')
      unregister('createdAtTo')
      unregister('scheduleDateFrom')
      unregister('scheduleDateTo')
      unregister('closedAtFrom')
      unregister('closedAtTo')
    }
  }, [register, unregister])

  useEffect(() => {
    if (filteredAccounts) {
      tableRef.current?.handleFetchPage()
    }
  }, [filteredAccounts])

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '1',
    })
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'id',
        header: 'Número',
        size: 115,
        cell: (info) => (
          <div className={styles.statusBox}>
            <span>{info.getValue().number}</span>
            <Popover.Root>
              <Popover.Content>
                <RichTooltip serviceOrderId={info.getValue().id} />
              </Popover.Content>
              <Popover.Trigger className={styles[info.getValue().status]}>
                {handleStatusLabel(info.getValue().status)}
                <InfoIcon />
              </Popover.Trigger>
            </Popover.Root>
          </div>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: 'account',
        header: 'Conta',
        cell: (info) =>
          `${info.getValue().account?.code} - ${info.getValue().customer?.name}`,
      }),
      columnHelper.accessor('type', {
        size: 68,
        header: 'Tipo',
        cell: (info) => (
          <>
            {
              SERVICE_ORDER_TYPE_EN_PT[
                info.getValue() as SERVICE_ORDER_TYPE_ENUM
              ]
            }
          </>
        ),
      }),
      columnHelper.accessor('technician', {
        header: 'Técnico',
        cell: (info) => info.getValue()?.name,
      }),
      columnHelper.accessor('createdAt', {
        size: 73,
        header: 'Abertura',
        cell: (info) => <>{maskedDateTime(info.getValue())}</>,
      }),
      columnHelper.accessor('closedAt', {
        size: 73,
        header: 'Fechamento',
        cell: (info) => (
          <>{info.getValue() && maskedDateTime(info.getValue())}</>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 42,
        cell: (info) => {
          const showDropList = actions.some((action) =>
            action.isVisible?.(info.getValue()),
          )

          const value = info.getValue()
          return showDropList ? (
            <Droplist
              trigger={<Icon name="menu-kebab" style={{ cursor: 'pointer' }} />}
            >
              <ul className={styles.dropListItem}>
                {actions.map((action) => {
                  const shouldShow = action.isVisible?.(value) ?? true

                  return (
                    shouldShow && (
                      <li
                        key={action.label}
                        onClick={() => action.handler(value)}
                      >
                        <Icon
                          name={action.Icon}
                          style={{ cursor: 'pointer' }}
                        />
                        {action.label}
                      </li>
                    )
                  )
                })}
              </ul>
            </Droplist>
          ) : (
            <Icon
              name={actions[0].Icon}
              onClick={() => actions[0].handler(value)}
              style={{ cursor: 'pointer' }}
            />
          )
        },
      }),
    ],
    [columnHelper, actions],
  )

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  return (
    <>
      {scheduleServiceOrderComponent.isVisible && (
        <ScheduleServiceOrderModal
          isVisible={scheduleServiceOrderComponent.isVisible}
          onClose={() => {
            form.reset()
            scheduleServiceOrderComponent.hide()
          }}
          serviceOrder={serviceOrderSchedule}
          onSave={(data) => {
            handleScheduleServiceOrder(data)
            scheduleServiceOrderComponent.hide()
          }}
        />
      )}
      <FormProvider {...form}>
        <CancelServiceOrder
          isVisible={cancelServiceOrderComponent.isVisible}
          onClose={() => {
            setSelectedServiceOrder(emptyServiceOrder)
            cancelServiceOrderComponent.hide()
          }}
          serviceOrder={{
            id: selectedServiceOrder?.id || '',
            number: selectedServiceOrder?.number || 0,
            technicianId: selectedServiceOrder?.technician?.id,
          }}
        />
        <div className={styles.wrapper}>
          {
            {
              view: (
                <div className={styles.tableContainer}>
                  <Table.Root>
                    <Table.Header>
                      {table?.getHeaderGroups().map((headerGroup) => (
                        <Table.Row key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <Table.ResizableColumn
                              key={header.id}
                              colSpan={header.colSpan}
                              style={{ width: header.column.getSize() }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            </Table.ResizableColumn>
                          ))}
                        </Table.Row>
                      ))}
                    </Table.Header>

                    <Table.Body className={styles.tbody}>
                      {table?.getRowModel().rows.map((row) => (
                        <Fragment key={row.id}>
                          <Table.Row>
                            {row.getVisibleCells().map((cell) => (
                              <Table.Cell
                                key={cell.id}
                                className={styles[cell.column.id]}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Table.Cell>
                            ))}
                          </Table.Row>
                        </Fragment>
                      ))}
                    </Table.Body>
                  </Table.Root>
                </div>
              ),
              empty: <EmptyState type="EmptyDataFromBFF" />,
              error: <EmptyState type="ErrorToLoadInfos" />,
              loading: (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ),
            }[handleRender(data?.data, isError, isFetching)]
          }
          <Pagination
            className={`${styles.pagination} ${filtersProps ? styles.paginationWrapper : ''}`}
            totalElements={data?.totalElements}
          />
        </div>
        {filtersProps?.isVisible && (
          <ServiceOrderFilters
            isVisible
            onClose={filtersProps.hide}
            onApplyFilters={() => {
              setFilters(watch())
              filtersProps.hide()
              handleResetPagination()
            }}
            hiddenFilters={['account', 'customer', 'patrimony']}
            onValidateFilters={validateFilters}
          />
        )}
      </FormProvider>
    </>
  )
}
