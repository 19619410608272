import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { EventInput } from '@fullcalendar/core'
import { FieldError, useForm } from 'react-hook-form'

import {
  Button,
  Calendar,
  ComboboxItem,
  Input,
  Modal,
  Datepicker,
  Tooltip,
  Popover,
} from 'components'
import { WeekInterval } from 'components/Calendar/types'

import { Combobox } from 'components/ComboboxV2/Combobox'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'

import { maskedInputDate, formatDecimal } from 'utilities/date'

import { formatTime } from '../../utilities/mask/date'

import {
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_SCHEDULE_PERIOD_ENUM,
  ServiceOrderContact,
  SERVICE_ORDER_TYPE_ENUM,
  useGetServiceOrders,
  ServiceOrderAggregatedQueryResponse,
} from 'services/serviceOrder'

import classNames from './ScheduleServiceOrderModal.module.scss'
import './ScheduleServiceOrderModal.css'
import { parseDataToComboboxV2 } from 'utilities/combobox'

import {
  formatServiceOrderScheduleDate,
  isServiceOrderReschedulable,
} from 'domains/serviceOrders/utilities'
import { useConfirmClose, useDebounce, useUserInfo } from 'shared/hooks'
import { useGetServiceOrderTags } from 'shared/hooks/serviceOrder/useGetServiceOrderTags'

import {
  ServiceOrderPayload,
  ServiceOrderSchedule,
} from 'domains/serviceOrders/types'
import { ContactQueryResponse } from 'services/contact/types'
import { useGetContacts } from 'shared/hooks/services'
import { formatGenericPhone } from 'domains/serviceOrders/utilities/mask/phone'
import { POSITION } from 'shared/hooks/useElementPosition/types'
import {
  QueryTechniciansResult,
  useInfinityTechnicians,
} from 'services/serviceOrder/hooks/useGetTechnicians'
import { useValidateTechnicianSchedules } from 'services/technicianSchedule'
import { TechnicianScheduleResponse } from 'services/technicianSchedule/types'
import { BlockedSchedule } from './components'
import { ReactComponent as WarningIcon } from 'assets/svg/blockedWarning.svg'
import { ATTENDANCE_ROLE } from 'services/coverages'
import { DayShift, ScheduleType } from './types'
import { checkScheduleOverlap, otherContact } from './utils'

type ScheduleServiceOrderModalProps = {
  isVisible: boolean
  onClose: () => void
  onSave: (data: ServiceOrderPayload) => void
  serviceOrder: ServiceOrderSchedule
  readonly?: boolean
}

const MAX_EVENTS_PER_DATE = 6

export function ScheduleServiceOrderModal({
  readonly,
  onClose,
  isVisible,
  onSave,
  serviceOrder,
}: ScheduleServiceOrderModalProps): JSX.Element | null {
  const {
    watch,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<ServiceOrderPayload>({
    defaultValues: serviceOrder,
  })

  useEffect(() => {
    if (isVisible) {
      reset({
        account: serviceOrder?.account || undefined,
        contact: serviceOrder?.contact || undefined,
        customer: serviceOrder?.customer || undefined,
        id: serviceOrder?.id || '',
        note: serviceOrder?.note || '',
        number: serviceOrder?.number || undefined,
        scheduleDate: serviceOrder?.scheduleDate || undefined,
        schedulePeriod: serviceOrder?.schedulePeriod || undefined,
        status: serviceOrder?.status || undefined,
        tags: serviceOrder?.tags || [],
        technician: serviceOrder?.technician || undefined,
        type: serviceOrder?.type || undefined,
        labels: serviceOrder?.labels || undefined,
      })
    }
  }, [serviceOrder, reset, isVisible])

  const { userInfo } = useUserInfo()

  const actualScheduleDate = watch('scheduleDate')
  const actualSchedulePeriod = watch('schedulePeriod')
  const contact = watch('contact')

  const [scheduleDateInput, setScheduleDateInput] = useState(
    actualSchedulePeriod ? maskedInputDate(actualSchedulePeriod) : '',
  )
  const [scheduleType, setScheduleType] = useState(ScheduleType.BUSINESS_HOURS)
  const [scheduleDate, setScheduleDate] = useState<Date>()
  const [calendarWeekInterval, setCalendarWeekInterval] =
    useState<WeekInterval>()

  const [dayShift, setDayShift] = useState(DayShift.MORNING)
  const [time, setTime] = useState('')
  const [filters, setFilters] = useState({
    tag: '',
    technician: '',
  })
  const [newServiceOrder, setNewServiceOrder] = useState<EventInput | null>(
    null,
  )
  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  const {
    isError: isErrorServiceOrderTags,
    isFetching: isFetchingServiceOrderTags,
    serviceOrderTags,
    fetchNextTagsPage,
  } = useGetServiceOrderTags(filters.tag)

  const {
    isError: isErrorTechnicians,
    isFetching: isFetchingTechnicians,
    technicians,
    fetchNextTechniciansPage,
  } = useInfinityTechnicians(
    serviceOrder?.account?.id,
    serviceOrder?.type as SERVICE_ORDER_TYPE_ENUM,
    filters.technician,
  )

  const { contacts, isFetchingContacts } = useGetContacts(
    { customerId: serviceOrder?.customer?.id },
    !!serviceOrder?.customer?.id,
  )

  const technicianId = useMemo(
    () => watch('technician')?.id ?? serviceOrder?.technician?.id,
    [watch('technician'), serviceOrder],
  )

  const { data: serviceOrders, isFetching } = useGetServiceOrders({
    ...(technicianId && { technicianIds: [technicianId] }),
    scheduleDateFrom:
      scheduleDate &&
      calendarWeekInterval?.startDate &&
      Math.min(scheduleDate.getTime(), calendarWeekInterval.startDate),
    scheduleDateTo:
      scheduleDate &&
      calendarWeekInterval?.endDate &&
      Math.max(scheduleDate.getTime(), calendarWeekInterval.endDate),
    status: [
      SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
      SERVICE_ORDER_STATUS_ENUM.RESCHEDULED,
      SERVICE_ORDER_STATUS_ENUM.FINISHED,
      SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
      SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION,
      SERVICE_ORDER_STATUS_ENUM.REPROVED,
      SERVICE_ORDER_STATUS_ENUM.PAUSED,
      SERVICE_ORDER_STATUS_ENUM.OPEN,
    ],
  })

  const currentServiceOrders: EventInput[] = useMemo(
    () =>
      serviceOrders?.data.map(
        (serviceOrder: ServiceOrderAggregatedQueryResponse) => ({
          id: serviceOrder?.id,
          title: serviceOrder.customer?.name || '',
          tags: serviceOrder.tags,
          allDay: !!serviceOrder?.schedulePeriod,
          start: new Date(serviceOrder?.scheduleDate),
          end:
            !serviceOrder?.schedulePeriod && serviceOrder?.scheduleDate
              ? new Date(serviceOrder?.scheduleDate).setHours(
                  new Date(serviceOrder?.scheduleDate).getHours() + 2,
                )
              : '',
          extendedProps: {
            schedulePeriod: serviceOrder?.schedulePeriod ?? undefined,
            status: serviceOrder?.status,
            number: serviceOrder?.number,
            accountCode: serviceOrder?.account?.code,
            customer: serviceOrder?.customer?.name,
          },
        }),
      ) || [],
    [serviceOrders],
  )

  const shouldConfirmClose =
    serviceOrder?.status === SERVICE_ORDER_STATUS_ENUM.OPEN &&
    (!!newServiceOrder?.extendedProps?.schedulePeriod ||
      (!!newServiceOrder?.start && !!newServiceOrder?.end))

  const { triedToClose, tooltipRef, tooltip, handleClose } = useConfirmClose({
    onClose: () => {
      reset()
      onClose()
    },
    shouldConfirm: shouldConfirmClose,
  })

  const { data: technicianSchedule } = useValidateTechnicianSchedules(
    watch('technician')?.id || serviceOrder?.technician?.id,
  )

  const hasServiceOrderAttendantRole = useMemo(
    () =>
      userInfo &&
      userInfo.userCoverages?.primary?.some(
        (coverage) =>
          coverage.coverageType === ATTENDANCE_ROLE.SERVICE_ORDER_ATTENDANT,
      ),
    [userInfo],
  )

  const hasReachedMaxEvents = useMemo(() => {
    if (!scheduleDate) return false

    const selectedDateEvents = currentServiceOrders.filter(
      (event) =>
        event.start instanceof Date &&
        event.start.toDateString() === scheduleDate.toDateString(),
    )

    return selectedDateEvents.length >= MAX_EVENTS_PER_DATE
  }, [currentServiceOrders, scheduleDate])

  const isTechnicianScheduleBlocked = useMemo(() => {
    if (technicianSchedule && scheduleDate) {
      const [hours, minutes] = time.split(':')
      const chosenStartTime = new Date(scheduleDate)
      chosenStartTime.setHours(Number(hours))
      chosenStartTime.setMinutes(Number(minutes))
      const chosenEndTime = new Date(chosenStartTime)
      chosenEndTime.setHours(chosenEndTime.getHours() + 2)

      return technicianSchedule.some((block: TechnicianScheduleResponse) => {
        const blockStartDate = new Date(block.startDate)
        const blockEndDate = new Date(block.endDate)
        const isOverlap = checkScheduleOverlap(
          blockStartDate,
          blockEndDate,
          scheduleDate,
          scheduleType,
          chosenStartTime,
          chosenEndTime,
        )

        return isOverlap
      })
    }

    return false
  }, [technicianSchedule, scheduleDate, scheduleType, time])

  const handleServiceOrderContacts = useCallback(
    (contacts: ContactQueryResponse[]) => {
      const parsedContacts: ServiceOrderContact[] = contacts.map((contact) => {
        return {
          id: contact.id,
          name: contact.name,
          countryCode: contact.phones?.[0].countryCode,
          provinceCode: contact.phones?.[0].provinceCode,
          number: contact.phones?.[0].number,
        }
      })

      parsedContacts.unshift(otherContact)

      return parsedContacts
    },
    [],
  )

  const allServiceOrders = useMemo(() => {
    if (newServiceOrder) {
      const filteredServiceOrders = currentServiceOrders.filter(
        (order) => order.id !== newServiceOrder.id,
      )
      return filteredServiceOrders.concat(newServiceOrder)
    }

    return currentServiceOrders
  }, [currentServiceOrders, newServiceOrder])

  useEffect(() => {
    setNewServiceOrder(null)
    if (!serviceOrder?.customer || !scheduleDate) return

    const currentStatus =
      serviceOrder?.status || SERVICE_ORDER_STATUS_ENUM.SCHEDULED

    const commonExtendedProps = {
      status: currentStatus,
      number: serviceOrder?.number,
      accountCode: serviceOrder?.account?.code,
      customer: serviceOrder?.customer?.name,
    }

    const scheduleTypes = {
      [ScheduleType.BUSINESS_HOURS]: () => ({
        id: serviceOrder?.id,
        title: serviceOrder?.customer?.name,
        allDay: true,
        start: new Date(scheduleDate.toDateString()),
        extendedProps: {
          schedulePeriod: 'BUSINESS_HOURS',
          ...commonExtendedProps,
        },
      }),
      [ScheduleType.DAY_SHIFT]: () => ({
        id: serviceOrder?.id,
        title: serviceOrder?.customer?.name,
        allDay: true,
        start: new Date(scheduleDate.toDateString()),
        extendedProps: {
          schedulePeriod: dayShift,
          ...commonExtendedProps,
        },
      }),
      [ScheduleType.FIXED_SCHEDULE]: () => {
        const [hours, minutes] = time.split(':')
        const startDate = new Date(scheduleDate)
        startDate.setHours(Number(hours))
        startDate.setMinutes(Number(minutes))

        const endDate = new Date(startDate)
        endDate.setHours(endDate.getHours() + 2)

        return {
          id: serviceOrder?.id,
          title: serviceOrder?.customer?.name,
          start: startDate,
          end: endDate,
          extendedProps: {
            schedulePeriod: undefined,
            ...commonExtendedProps,
          },
        }
      },
    }

    const newEvent = scheduleTypes[scheduleType]?.()
    if (newEvent) {
      setNewServiceOrder(newEvent)
    }
  }, [watch, scheduleType, scheduleDate, time, dayShift])

  const handleChangeScheduleDate = useCallback((value: Date) => {
    setScheduleDate(value)
    setScheduleDateInput(convertDateToString(value))
  }, [])

  const handleChangeScheduleDateInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const currentDate = new Date()

        const isGreaterThanTheCurrentDate =
          convertStringToDate(value) > currentDate

        isGreaterThanTheCurrentDate
          ? setScheduleDate(convertStringToDate(value))
          : setScheduleDate(undefined)
      } else {
        setScheduleDate(undefined)
      }
      setScheduleDateInput(maskedInputDate(value))
    },
    [],
  )

  const shouldDisableSaveButton =
    !newServiceOrder ||
    isFetching ||
    ((isTechnicianScheduleBlocked || hasReachedMaxEvents) &&
      !hasServiceOrderAttendantRole)

  const handleChangeScheduleType = useCallback((value: ScheduleType) => {
    setTime('')
    setDayShift(DayShift.MORNING)

    setScheduleType(value)
  }, [])

  useEffect(() => {
    if (actualScheduleDate) {
      const scheduleDate = new Date(actualScheduleDate)
      handleChangeScheduleDate(scheduleDate)
      const scheduleHours = scheduleDate.getHours()
      const scheduleMinutes = scheduleDate.getMinutes()

      if (scheduleHours) {
        handleChangeScheduleType(ScheduleType.FIXED_SCHEDULE)
        setTime(
          formatTime(
            `${formatDecimal(scheduleHours)}:${formatDecimal(scheduleMinutes)}`,
          ),
        )
      }

      const types = {
        BUSINESS_HOURS: () =>
          handleChangeScheduleType(ScheduleType.BUSINESS_HOURS),
        MORNING: () => {
          handleChangeScheduleType(ScheduleType.DAY_SHIFT)
          setDayShift(DayShift.MORNING)
        },
        AFTERNOON: () => {
          handleChangeScheduleType(ScheduleType.DAY_SHIFT)
          setDayShift(DayShift.AFTERNOON)
        },
      }

      if (actualSchedulePeriod) {
        types[actualSchedulePeriod]?.()
      }
    }
  }, [
    actualScheduleDate,
    actualSchedulePeriod,
    handleChangeScheduleType,
    handleChangeScheduleDate,
  ])

  const onSubmit = useCallback(
    (data: ServiceOrderPayload) => {
      let schedulePeriodForm
      if (newServiceOrder && newServiceOrder?.extendedProps) {
        const { schedulePeriod } =
          newServiceOrder.extendedProps as ServiceOrderPayload
        setValue('schedulePeriod', schedulePeriod)
        schedulePeriodForm = schedulePeriod
      }
      const scheduleDate = newServiceOrder?.start as Date
      scheduleDate.setSeconds(0)
      scheduleDate.setMilliseconds(0)

      setValue('scheduleDate', scheduleDate.getTime())

      const currentNote = watch('note')
      const defaultNote = `OS agendada para ${formatServiceOrderScheduleDate({
        scheduleDate: scheduleDate.getTime() || 0,
        schedulePeriod:
          schedulePeriodForm as SERVICE_ORDER_SCHEDULE_PERIOD_ENUM,
      })}. Agendamento realizado por ${
        userInfo ? userInfo.name : ''
      } via sistema web.`

      const newNote = currentNote
        ? currentNote.includes(defaultNote)
          ? currentNote
          : `${defaultNote} ${currentNote}`
        : defaultNote

      setValue('note', newNote)

      const isReschedulable =
        serviceOrder?.status &&
        isServiceOrderReschedulable(serviceOrder?.status)

      onSave({
        ...data,
        note: newNote,
        scheduleDate: Number(scheduleDate),
        schedulePeriod: watch('schedulePeriod'),
        status: isReschedulable
          ? SERVICE_ORDER_STATUS_ENUM.RESCHEDULED
          : SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
        contact: watch('contact'),
      })
    },
    [newServiceOrder, setValue, userInfo, watch],
  )

  return (
    <Modal
      title={
        readonly
          ? 'Agenda'
          : `Agendamento da ordem de serviço ${
              serviceOrder?.number ? `#${serviceOrder?.number}` : ''
            }`
      }
      className="schedule-service-order-modal"
      size="lg"
      isVisible={isVisible}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classNames.container}>
          {!readonly && (
            <div className={classNames.left}>
              <Combobox
                id="technician-input"
                label={{
                  text: 'Técnico responsável',
                }}
                {...register('technician')}
                itemLabel={(item) => {
                  const technicianItem =
                    item as ComboboxItem<QueryTechniciansResult>

                  return technicianItem.value.isAccountTechnician
                    ? 'Responsável'
                    : ''
                }}
                onSearch={(value) => handleFilter({ technician: value })}
                items={parseDataToComboboxV2(technicians || [], 'name')}
                value={watch('technician')?.name || ''}
                onChange={(selected) => {
                  const technician =
                    selected as ComboboxItem<QueryTechniciansResult>

                  if (technician) {
                    setValue('technician', technician.value)
                  }
                }}
                isLoading={isFetchingTechnicians}
                isError={isErrorTechnicians}
                onEndReached={fetchNextTechniciansPage}
              />

              {isTechnicianScheduleBlocked && (
                <Popover.Root>
                  <Popover.Trigger asChild>
                    <div className={classNames.blockWarning}>
                      <WarningIcon />
                      <span>
                        A agenda do técnico possui bloqueios na semana
                      </span>
                    </div>
                  </Popover.Trigger>
                  <Popover.Content>
                    <BlockedSchedule
                      technician={serviceOrder?.technician?.name || ''}
                      technicianSchedule={technicianSchedule || []}
                    />
                  </Popover.Content>
                </Popover.Root>
              )}
              <Combobox
                {...register('contact')}
                id="contact-input"
                label={{
                  text: 'Contato Responsável',
                }}
                items={parseDataToComboboxV2(
                  handleServiceOrderContacts(contacts || []),
                  'name',
                )}
                value={
                  watch('contact')
                    ? {
                        label: 'name',
                        value: watch('contact')?.id
                          ? watch('contact')
                          : otherContact,
                      }
                    : undefined
                }
                onChange={(selected) => {
                  const selectedContact =
                    selected as ComboboxItem<ServiceOrderContact>

                  if (selectedContact) {
                    setValue('contact', selectedContact.value)
                  }
                }}
                isLoading={isFetchingContacts}
              />

              {!watch('contact')?.id ? (
                <div className={classNames.anotherContactData}>
                  <Input
                    label="Nome"
                    id="contact-name"
                    autoComplete="off"
                    placeholder="Nome completo"
                    value={
                      watch('contact') && watch('contact')?.name !== 'Outro'
                        ? watch('contact')?.name
                        : ''
                    }
                    onChange={(e) => {
                      setValue('contact', {
                        ...contact,
                        name: e.target?.value,
                      })
                    }}
                    errorMessage={(errors.contact?.name as FieldError)?.message}
                  />
                  <Input
                    label="Telefone"
                    errorMessage={
                      (errors.contact?.number as FieldError)?.message
                    }
                    maxLength={15}
                    value={
                      watch('contact')
                        ? formatGenericPhone(
                            String(watch('contact')?.provinceCode || '') +
                              String(watch('contact')?.number || ''),
                          )
                        : ''
                    }
                    onChange={(e) => {
                      setValue('contact', {
                        ...getValues('contact'),
                        countryCode: 55,
                        provinceCode: e.target?.value
                          .replace(/\D/g, '')
                          .substring(0, 2),
                        number: e.target?.value.replace(/\D/g, '').substring(2),
                      })
                    }}
                  />
                </div>
              ) : null}

              <span className={classNames.dateLabel}>Data do agendamento</span>
              <Datepicker
                selectEnd
                id="scheduleDateModal"
                initialDate={new Date()}
                initialValueInput={scheduleDateInput}
                {...register('scheduleDate')}
                onChangeInput={handleChangeScheduleDateInput}
                onChangeDate={handleChangeScheduleDate}
              />

              <span className={classNames.radioLabel}>Agendar em:</span>
              <div className={classNames.radioWrapper}>
                <input
                  id="businessHours"
                  type="radio"
                  className={classNames.radio}
                  checked={scheduleType === ScheduleType.BUSINESS_HOURS}
                  onChange={() =>
                    handleChangeScheduleType(ScheduleType.BUSINESS_HOURS)
                  }
                />
                <label
                  htmlFor="businessHours"
                  className={classNames.radioLabel}
                >
                  Horário comercial
                </label>
              </div>
              <div className={classNames.radioWrapper}>
                <input
                  id="dayShift"
                  type="radio"
                  className={classNames.radio}
                  checked={scheduleType === ScheduleType.DAY_SHIFT}
                  onChange={() =>
                    handleChangeScheduleType(ScheduleType.DAY_SHIFT)
                  }
                />
                <label htmlFor="dayShift" className={classNames.radioLabel}>
                  Turno
                </label>
              </div>
              <div
                {...register('schedulePeriod')}
                className={[
                  classNames.radioWrapper,
                  classNames.lastRadioWrapper,
                ].join(' ')}
              >
                <input
                  id="fixedSchedule"
                  type="radio"
                  className={classNames.radio}
                  checked={scheduleType === ScheduleType.FIXED_SCHEDULE}
                  onChange={() =>
                    handleChangeScheduleType(ScheduleType.FIXED_SCHEDULE)
                  }
                />
                <label
                  htmlFor="fixedSchedule"
                  className={classNames.radioLabel}
                >
                  Horário fixo
                </label>
              </div>

              {scheduleType === ScheduleType.DAY_SHIFT ? (
                <Combobox
                  id="day-shift-input"
                  value={dayShift === DayShift.MORNING ? 'Manhã' : 'Tarde'}
                  label={{ text: 'Turno' }}
                  items={['Manhã', 'Tarde']}
                  onChange={(value) => {
                    setDayShift(
                      value === 'Manhã' ? DayShift.MORNING : DayShift.AFTERNOON,
                    )
                  }}
                />
              ) : null}

              {scheduleType === ScheduleType.FIXED_SCHEDULE ? (
                <Input
                  id="hour-input"
                  label="Horário"
                  placeholder="00:00"
                  value={time}
                  maxLength={5}
                  onChange={(event) => setTime(formatTime(event.target.value))}
                />
              ) : null}

              <div className={classNames.noteBox}>
                <label>Observações</label>
                <textarea
                  rows={10}
                  {...register('note')}
                  onChange={(event) =>
                    setValue('note', event.target.value, {
                      shouldValidate: true,
                    })
                  }
                  maxLength={5000}
                  value={watch('note')}
                />
              </div>

              <Combobox
                id="service-order-tags"
                label={{
                  text: 'Tags',
                }}
                multiple
                onSearch={(value) => handleFilter({ tag: value })}
                items={serviceOrderTags?.map(
                  (serviceOrderTag) => serviceOrderTag.name,
                )}
                {...register('tags')}
                {...(watch('tags') && {
                  value: watch('tags')?.map(
                    (serviceOrderTag) => serviceOrderTag.name,
                  ),
                })}
                onChange={(selecteds) => {
                  const tagsSelected = selecteds as string[]
                  setValue(
                    'tags',
                    tagsSelected.map((tag) => ({ name: tag })),
                  )
                }}
                isLoading={isFetchingServiceOrderTags}
                isError={isErrorServiceOrderTags}
                onEndReached={fetchNextTagsPage}
              />
            </div>
          )}

          <div className={classNames.right}>
            <Calendar
              height="calc(80vh - 188px)"
              events={allServiceOrders}
              onChangeWeekInterval={(newInterval) => {
                if (
                  calendarWeekInterval?.startDate !== newInterval.startDate ||
                  calendarWeekInterval?.endDate !== newInterval.endDate
                ) {
                  setCalendarWeekInterval(newInterval)
                }
              }}
            />
          </div>
        </div>
        <Modal.Footer className={classNames.modalFooter}>
          <div ref={tooltipRef} onClick={tooltip.show}>
            <Button
              width="172px"
              buttonTitle="Voltar"
              onClick={handleClose}
              type={triedToClose ? 'cancel-secondary' : 'secondary'}
            />
          </div>
          {triedToClose && (
            <Tooltip
              positionAbove={POSITION.TOP}
              parentRef={tooltipRef}
              type="informative"
              isVisible={tooltip.isVisible}
            >
              <span>
                Existem informações que não foram salvas. Deseja realmente
                fechar?
              </span>
            </Tooltip>
          )}
          {!readonly && (
            <Button
              width="172px"
              buttonTitle="Salvar"
              disabled={shouldDisableSaveButton}
              type="primary"
              htmlType="submit"
            />
          )}
        </Modal.Footer>
      </form>
    </Modal>
  )
}
