import { joiResolver } from '@hookform/resolvers/joi'
import { Button, Input, Modal, Loader } from 'components'
import { useToast, useToggle, useUserInfo } from '../../../../shared/hooks'

import { useForm } from 'react-hook-form'
import { ServiceOrderCancel } from '../../types'

import { cancelServiceOrderSchema } from '../../schemas/cancelServiceOrderSchema'

import {
  ServiceOrderDriver,
  SERVICE_ORDER_STATUS_ENUM,
} from '../../../../services/serviceOrder'
import { AuthDriver, LoginResponse } from '../../../../services/auth'

import styles from './CancelServiceOrder.module.scss'
import { formatDecimal } from 'utilities/date'

export type ServiceOrderFragment = {
  id: string
  number: number
  technicianId?: string
  [key: string]: unknown
}

export type CancelServiceOrderProps = {
  isVisible: boolean
  onClose: () => void
  serviceOrder: ServiceOrderFragment
}

export const CancelServiceOrder = ({
  isVisible,
  onClose,
  serviceOrder,
}: CancelServiceOrderProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ServiceOrderCancel>({
    mode: 'onBlur',
    resolver: joiResolver(cancelServiceOrderSchema),
  })

  const password = watch('password')
  const notes = watch('notes')

  const loader = useToggle()
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()

  const cancelServiceOrder = async () => {
    const username = userInfo.username

    let loginResponse: LoginResponse = {
      access_token: '',
      refresh_token: '',
      expires_in: 0,
      token_type: '',
    }

    try {
      loader.show()
      loginResponse = await AuthDriver.login({
        login: username,
        password,
      })
    } catch (error) {
      loader.hide()
      addToast({
        type: 'alert',
        message: 'Senha inválida. Tente novamente',
      })
    }

    if (loginResponse.access_token) {
      try {
        await ServiceOrderDriver.updateStatus(serviceOrder?.id || '', {
          status: SERVICE_ORDER_STATUS_ENUM.CANCELED,
          note: notes,
          userId: userInfo.id,
          technicianId: serviceOrder?.technicianId,
        })

        addToast({
          error: false,
          message: `Ordem de serviço #${formatDecimal(
            serviceOrder.number,
          )} cancelada com sucesso.`,
        })
        onClose()
      } catch (error) {
        addToast({
          type: 'alert',
          message: 'Erro ao cancelar ordem de serviço. Tente novamente',
        })
        onClose()
      } finally {
        loader.hide()
      }
    }
  }

  return (
    <>
      <Loader isVisible={loader.isVisible} />
      <Modal
        size="sm"
        id="confirm-so-cancel-modal"
        title={`Cancelar ordem de serviço #${serviceOrder.number} ?`}
        isVisible={isVisible}
        onClose={onClose}
      >
        <form className={styles.container}>
          <div>
            <p className={styles.subtitle}>
              Informe a sua senha de login para confirmar a ação.
            </p>
            <label className={styles.label}>Usuário</label>
            <p className={styles.loginInfo}>{userInfo.name || ''}</p>
          </div>
          <Input
            id="cancel-so-password-input"
            label="Senha"
            placeholder="••••••••"
            password
            {...register('password')}
            autoComplete="off"
            errorMessage={errors?.password?.message}
          />
          <div className={styles.textAreaContainer}>
            <label className="label" htmlFor="notes">
              Observações
            </label>
            <textarea
              maxLength={5000}
              className={styles.textArea}
              rows={5}
              placeholder="Observações para cancelamento da ordem de serviço"
              {...register('notes')}
              id="notes"
            />
          </div>
        </form>
        <Modal.Footer>
          <Button
            width="172px"
            id="confirm-service-error-button"
            buttonTitle="Cancelar"
            type="cancel-primary"
            disabled={!password || !notes}
            onClick={handleSubmit(cancelServiceOrder)}
          />
          <Button
            width="172px"
            id="cancel-service-error-button"
            buttonTitle="Voltar à lista"
            type="secondary"
            disabled={false}
            onClick={onClose}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
