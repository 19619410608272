import {
  ActionOccurrenceStateName,
  OccurrenceStateName,
} from 'domains/attendancePolicy/components/RuleForm/data/occurrenceStateName'
import { Fragment } from 'domains/attendancePolicy/types'
import { OccurrenceInterventionTypeName } from 'services/displacementMap/types'
import { EventTypeResponse } from 'services/event/types'
import { OccurrenceTypeName } from 'services/occurrence/types'
import { Evaluation, SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder'
import { TimeUnit } from 'services/serviceType/types'

export enum SensorType {
  MAIN = 'MAIN',
  TAMPER_CASE = 'TAMPER_CASE',
  ANTI_SABOTAGE = 'ANTI_SABOTAGE',
  ACCELEROMETER = 'ACCELEROMETER',
  DRY_CONTACT = 'DRY_CONTACT',
  CENTRAL = 'CENTRAL',
  UNKNOWN = 'UNKNOWN',
}

export enum TriggerType {
  OCCURRENCE_ARRIVAL = 'OCCURRENCE_ARRIVAL',
  OCCURRENCE_CLOSING = 'OCCURRENCE_CLOSING',
  EVENT_ARRIVAL = 'EVENT_ARRIVAL',
  TIME_BASED = 'TIME_BASED',
  INTERVENTION_CREATED = 'INTERVENTION_CREATED',
}

export const partitionStatus = {
  ARMED: 'ARMED',
  DISARMED: 'DISARMED',
  STAYED: 'STAYED',
  TRIGGERED: 'TRIGGERED',
}

export type PartitionStatus = keyof typeof partitionStatus

export enum BranchOperation {
  AND = 'AND',
  OR = 'OR',
}

export enum ConditionType {
  OCCURRENCE_STATE_NAMES = 'OCCURRENCE_STATE_NAMES',
  OCCURRENCE_HAS_EVENTS = 'OCCURRENCE_HAS_EVENTS',
  OCCURRENCE_EVENTS_MITIGATION = 'OCCURRENCE_EVENTS_MITIGATION',
  ACCOUNT_TAGS = 'ACCOUNT_TAGS',
  OCCURRENCE_TAGS = 'OCCURRENCE_TAGS',
  DEVICE_TAGS = 'DEVICE_TAGS',
  ACCOUNT_TRAVEL_LIMIT = 'ACCOUNT_TRAVEL_LIMIT',
  SERVICE_ORDER_TYPES = 'SERVICE_ORDER_TYPES',
  SERVICE_ORDER_ACCOUNT_TAGS = 'SERVICE_ORDER_ACCOUNT_TAGS',
  CENTRAL_PARTITIONS_STATUS = 'CENTRAL_PARTITIONS_STATUS',
  DEVICE_QUANTITY = 'DEVICE_QUANTITY',
  SERVICE_TYPE = 'SERVICE_TYPE',
  OCCURRENCE_FINISHED_MANUALLY = 'OCCURRENCE_FINISHED_MANUALLY',
  EVENT_IMAGE_DETECTIONS = 'EVENT_IMAGE_DETECTIONS',
}

export enum ActionType {
  CHANGE_OCCURRENCE_STATE = 'CHANGE_OCCURRENCE_STATE',
  CHANGE_OCCURRENCE_PRIORITY = 'CHANGE_OCCURRENCE_PRIORITY',
  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
  SEND_OCCURRENCE_CUSTOM_EVENT = 'SEND_OCCURRENCE_CUSTOM_EVENT',
  REQUIRE_PHONE_CALL = 'REQUIRE_PHONE_CALL',
  CHANGE_OCCURRENCE_TITLE = 'CHANGE_OCCURRENCE_TITLE',
  CHANGE_FINISH_RESTRICTION = 'CHANGE_FINISH_RESTRICTION',
}

export type CustomerCoverage = {
  id: string
  name: string
}

export type AccountCoverage = {
  id: string
  aggregatedAccountName: string
  customer: CustomerCoverage
  patrimony: Fragment
}

export type PartitionCoverage = {
  id: string
  name: string
}

export type AttendancePolicyQueryRequest = {
  name?: string
  serviceTypeId?: string
  occurrenceTypeId?: string
  active?: boolean
  hasSelectors?: boolean
  customerId?: string
  accountId?: string
  partitionId?: string
  createdFrom?: number
  createdTo?: number
  page: number
  size: number
  sort?: string
}

export type CoverageFragment = {
  id: string
  name: string
}

export type AttendancePolicyQueryResponse = {
  id: string
  name: string
  active: boolean
  createdAt: number
  occurrenceTypeName: OccurrenceTypeName
  serviceTypeName: string
  coverages: CoverageFragment[]
}

export type Parameters = {
  phoneCallToPoliceEnabled: boolean
  vehicleTravelEnabled: boolean
  finishOccurrenceWithoutPhoneCallEnabled: boolean
  finishOccurrenceByDutyEnabled: boolean
}

export type ImageDetectionLabel = 'PERSON' | 'PET'

export type AggregatedFacts = {
  occurrenceStateNames?: OccurrenceStateName[]
  allEventsMitigated?: boolean
  serviceOrderTypes?: SERVICE_ORDER_TYPE_ENUM[]
  travelLimitReached?: boolean
  accountTags?: { id: string; name: string }[]
  deviceTags?: { id: string; name: string }[]
  occurrenceTags?: { id: string; name: string }[]
  serviceOrderTags?: { id: string; name: string }[]
  eventType?: EventTypeResponse
  numberOfEventsFromEventType?: number
  eventsFromEventTypeHasSameDevices?: boolean
  minDevices?: number
  maxDevices?: number
  serviceType?: { id: string; name: string }
  partitionStatus?: PartitionStatus
  allPartitions?: boolean
  imageDetections?: ImageDetectionLabel[]
}

export type AggregatedTrigger = {
  type: TriggerType
  parameters: {
    occurrenceTypeId?: string
    sensorTypes?: SensorType[]
    timeInterval?: number
    timeUnit?: TimeUnit
    occurrenceState?: OccurrenceStateName
    eventType?: EventTypeResponse[]
    interventionType?: OccurrenceInterventionTypeName
  }
}

export type AggregatedCondition = {
  type: ConditionType
  facts: AggregatedFacts
  evaluation: Evaluation
}

export type AggregatedBranch = {
  index: number
  branchOperation?: BranchOperation
  conditionsOperation?: BranchOperation
  conditions: AggregatedCondition[]
}

export enum NotificationType {
  SOUND = 'SOUND',
}

export const phoneCallTarget = {
  ACTION_PLAN: 'ACTION_PLAN',
  CENTRAL: 'CENTRAL',
  PATRIMONY: 'PATRIMONY',
  POLICE: 'POLICE',
  TACTICAL_AGENT: 'TACTICAL_AGENT',
}

export type PhoneCallTarget = keyof typeof phoneCallTarget

export type AggregatedActionParameters = {
  occurrenceStateName?: ActionOccurrenceStateName
  occurrencePriority?: number
  occurrenceTitle?: string
  phoneCallTarget?: PhoneCallTarget
  sendNotification?: {
    type: NotificationType
    soundId?: string
    soundName?: string
  }
  stringifiedPayload?: string
  allowFinishByDuty?: boolean
}

export type Action = {
  type: ActionType
  index: number
  parameters: AggregatedActionParameters
}

export type AggregatedRule = {
  index: number
  name: string
  actions: Action[]
  trigger: AggregatedTrigger
  branches: AggregatedBranch[]
}

export type CoverageGrouper = {
  customers: CustomerCoverage[]
  accounts: AccountCoverage[]
}

export type AggregatedAttendPolicyResponse = {
  id: string
  name: string
  active?: boolean
  parameters: Parameters
  serviceType: {
    id: string
    name: string
  }
  occurrenceType: {
    id: string
    name: string
  }
  occurrencePriority: number
  occurrenceTitle?: string
  coverages: CoverageGrouper
  rules: AggregatedRule[]
}

export type TriggerParameters = {
  occurrenceTypeId?: string
  eventTypeIds?: string[]
  sensorTypes?: SensorType[]
  timeInterval?: number
  timeUnit?: string
  occurrenceState?: OccurrenceStateName
  interventionType?: OccurrenceInterventionTypeName
}

export type Trigger = {
  type: TriggerType
  parameters: TriggerParameters
}

export declare enum ServiceOrderType {
  INSTALL = 'INSTALL',
  MAINTENANCE = 'MAINTENANCE',
  REMOTE_MAINTENANCE = 'REMOTE_MAINTENANCE',
  UNINSTALL = 'UNINSTALL',
}

export type Facts = {
  occurrenceStateNames?: OccurrenceStateName[]
  eventTypeIds?: string[]
  allEventsMitigated?: boolean
  serviceOrderTypes?: SERVICE_ORDER_TYPE_ENUM[]
  travelLimitReached?: boolean
  tagIds?: string[]
  numberOfEventsFromEventType?: number
  eventsFromEventTypeHasSameDevices?: boolean
  minDevices?: number
  maxDevices?: number
  serviceTypeId?: string
  partitionStatus?: PartitionStatus
  allPartitions?: boolean
  imageDetections?: ImageDetectionLabel[]
}

export type Condition = {
  type: ConditionType
  facts: Facts
}

export type Branch = {
  index: number
  branchOperation?: BranchOperation
  conditionsOperation?: BranchOperation
  conditions: Condition[]
}

export type Rule = {
  name: string
  index: number
  trigger: Trigger
  branches: Branch[]
  actions: Action[]
}

export type Selectors = {
  customerIds: string[]
  accountIds: string[]
}

export type AttendancePolicyPayload = {
  name: string
  active?: boolean
  occurrenceTitle?: string
  serviceTypeId: string
  occurrenceTypeId: string
  occurrencePriority: number
  parameters: Parameters
  selectors: Selectors
  rules: Rule[]
}
