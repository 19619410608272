import { translatedOccurrenceState } from 'domains/attendancePolicy/components/RuleForm/data/occurrenceStateName'
import {
  translatedNotificationType,
  translatedPhoneCallTarget,
} from 'domains/attendancePolicy/components/RuleForm/utilities/action/utilities'
import { ReactNode } from 'react'
import {
  ActionType,
  AggregatedActionParameters,
} from 'services/attendancePolicy/types'

const handleChangeOccurrenceState = ({
  occurrenceStateName,
}: AggregatedActionParameters) => {
  const fragment = (
    <>
      Alterar estado para
      <span>
        {occurrenceStateName &&
          translatedOccurrenceState[occurrenceStateName].toLowerCase()}
      </span>
    </>
  )

  return {
    resume: fragment,
    accordion: fragment,
  }
}

const handleChangeOccurrencePriority = ({
  occurrencePriority,
}: AggregatedActionParameters) => {
  const fragment = (
    <>
      Alterar prioridade para
      <span>{occurrencePriority}</span>
    </>
  )

  return {
    resume: fragment,
    accordion: fragment,
  }
}

const handleSendNotification = ({
  sendNotification,
}: AggregatedActionParameters) => {
  if (sendNotification) {
    const fragment = (
      <>
        Notificar via
        <span>
          {`${translatedNotificationType[sendNotification.type]}
          estilo ${sendNotification.soundName}`}
        </span>
      </>
    )

    return {
      resume: fragment,
      accordion: fragment,
    }
  }

  return {
    resume: <></>,
    accordion: <></>,
  }
}

const handlePhoneCall = ({ phoneCallTarget }: AggregatedActionParameters) => {
  if (phoneCallTarget) {
    const fragment = (
      <>
        Ligar para
        <span>{translatedPhoneCallTarget[phoneCallTarget]}</span>
      </>
    )

    return {
      resume: fragment,
      accordion: fragment,
    }
  }

  return {
    resume: <></>,
    accordion: <></>,
  }
}

const handleCustomEvent = () => {
  const fragment = (
    <>
      Envio de payload para
      <span>serviço externo</span>
    </>
  )

  return {
    resume: fragment,
    accordion: fragment,
  }
}

const handleOccurrenceTitle = ({
  occurrenceTitle,
}: AggregatedActionParameters) => {
  const fragment = (
    <>
      Alterar título para
      <span>{occurrenceTitle ?? ''}</span>
    </>
  )

  return {
    resume: fragment,
    accordion: fragment,
  }
}

const handleChangeFinishRestriction = ({
  allowFinishByDuty,
}: AggregatedActionParameters) => {
  const finishRestriction = allowFinishByDuty ? 'Permitir' : 'Não permitir'

  const accordion = (
    <>
      <span>{finishRestriction}</span>a finalização da ocorrência pelo tático
    </>
  )

  const resume = (
    <>
      Alterar finalização <span>{finishRestriction}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

type ActionTypeLabel = { resume: ReactNode; accordion: ReactNode }

export const actionTypeLabel: Record<
  ActionType,
  (parameters: AggregatedActionParameters) => ActionTypeLabel
> = {
  [ActionType.CHANGE_OCCURRENCE_STATE]: handleChangeOccurrenceState,
  [ActionType.CHANGE_OCCURRENCE_PRIORITY]: handleChangeOccurrencePriority,
  [ActionType.SEND_NOTIFICATION]: handleSendNotification,
  [ActionType.REQUIRE_PHONE_CALL]: handlePhoneCall,
  [ActionType.SEND_OCCURRENCE_CUSTOM_EVENT]: handleCustomEvent,
  [ActionType.CHANGE_OCCURRENCE_TITLE]: handleOccurrenceTitle,
  [ActionType.CHANGE_FINISH_RESTRICTION]: handleChangeFinishRestriction,
}
