import * as Joi from '@hapi/joi'
import { Fragment } from 'domains/attendancePolicy/types'
import { tagSchema } from 'domains/attendanceProfile/schemas'

import {
  AccountCoverage,
  Action,
  ActionType,
  AggregatedAttendPolicyResponse,
  AggregatedBranch,
  AggregatedRule,
  AggregatedTrigger,
  BranchOperation,
  ConditionType,
  CoverageGrouper,
  NotificationType,
  Parameters,
  SensorType,
  TriggerType,
  partitionStatus,
  phoneCallTarget,
} from 'services/attendancePolicy/types'
import { EventTypeResponse } from 'services/event/types'

import { SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder'

import { StringSchemeErrorsEnum } from 'utilities/validation'
import {
  actionOccurrenceStateName,
  conditionOccurrenceStateName,
} from 'domains/attendancePolicy/components/RuleForm/data/occurrenceStateName'
import { occurrenceInterventionTypeName } from 'services/displacementMap/types'
import { validateJSON } from 'domains/attendancePolicy/components/RuleForm/utilities/action/utilities'

const fragmentSchema = Joi.object<Fragment>({
  id: Joi.string(),
  name: Joi.string(),
})

const accountCoverageSchema = Joi.object<AccountCoverage>({
  id: Joi.string().uuid(),
  aggregatedAccountName: Joi.string(),
  customer: fragmentSchema,
  patrimony: fragmentSchema,
})

const eventTypeSchema = Joi.object<EventTypeResponse>({
  id: Joi.string().optional(),
  name: Joi.string().optional(),
  description: Joi.string().optional(),
  eventRuleId: Joi.string().optional(),
  notify: Joi.boolean().optional(),
  createOccurrence: Joi.boolean().optional(),
  createOccurrenceType: Joi.string().allow(null).optional(),
  finishOccurrenceType: Joi.string().allow(null).optional(),
  mitigateEventType: Joi.string().allow(null).optional(),
  createdAt: Joi.number().optional(),
  updatedAt: Joi.number().optional(),
  aggregatedCodeDescription: Joi.string().optional(),
  auxiliaryType: Joi.string().optional(),
}).unknown(true)

export const triggerSchema = Joi.object<AggregatedTrigger>({
  type: Joi.string()
    .valid(...Object.values(TriggerType))
    .required(),
  parameters: Joi.object({
    occurrenceTypeId: Joi.string().uuid().allow(' ').optional(),
    interventionType: Joi.string()
      .valid(...Object.values(occurrenceInterventionTypeName))
      .optional(),
    sensorTypes: Joi.array()
      .items(Joi.string().valid(...Object.values(SensorType)))
      .optional(),
  })
    .when('type', {
      is: TriggerType.TIME_BASED,
      then: Joi.object({
        timeInterval: Joi.number().min(1).required(),
        timeUnit: Joi.string().required(),
        occurrenceState: Joi.string()
          .valid(...Object.values(conditionOccurrenceStateName))
          .required(),
      }),
    })
    .when('type', {
      is: TriggerType.EVENT_ARRIVAL,
      then: Joi.object({
        eventType: Joi.array().items(eventTypeSchema).min(1).required(),
      }),
    }),
})

export const conditionSchema = Joi.object({
  type: Joi.string()
    .valid(...Object.values(ConditionType))
    .required(),
  evaluation: Joi.string().valid('HAS', 'HASNT').optional(),
  facts: Joi.alternatives().conditional('type', {
    is: ConditionType.OCCURRENCE_FINISHED_MANUALLY,
    then: Joi.optional(), // `facts` pode ser undefined
    otherwise: Joi.object({
      occurrenceStateNames: Joi.array()
        .items(
          Joi.string().valid(...Object.values(conditionOccurrenceStateName)),
        )
        .min(1)
        .optional(),
      allEventsMitigated: Joi.boolean().optional(),
      serviceOrderTypes: Joi.array()
        .items(Joi.string().valid(...Object.values(SERVICE_ORDER_TYPE_ENUM)))
        .min(1)
        .optional(),
      travelLimitReached: Joi.boolean().optional(),
      eventType: eventTypeSchema.optional(),
      eventsFromEventTypeHasSameDevices: Joi.boolean().optional(),
      numberOfEventsFromEventType: Joi.number().min(1).max(999).optional(),
      serviceOrderTags: Joi.array().items(tagSchema).min(1).optional(),
      accountTags: Joi.array().items(tagSchema).min(1).optional(),
      deviceTags: Joi.array().items(tagSchema).min(1).optional(),
      occurrenceTags: Joi.array().items(tagSchema).min(1).optional(),
      partitionStatus: Joi.string()
        .valid(...Object.values(partitionStatus))
        .optional(),
      allPartitions: Joi.boolean().optional(),
      minDevices: Joi.number().min(0).max(10000).optional(),
      maxDevices: Joi.number().min(0).max(10000).optional(),
      serviceType: fragmentSchema.optional(),
      imageDetections: Joi.array()
        .items(Joi.string().valid('PERSON', 'PET'))
        .optional(),
    })
      .when('type', {
        is: ConditionType.OCCURRENCE_HAS_EVENTS,
        then: Joi.object({
          eventType: eventTypeSchema.required(),
          numberOfEventsFromEventType: Joi.number().min(1).max(999).required(),
        }),
      })
      .when('type', {
        is: ConditionType.CENTRAL_PARTITIONS_STATUS,
        then: Joi.object({
          partitionStatus: Joi.string()
            .valid(...Object.values(partitionStatus))
            .required(),
          allPartitions: Joi.boolean().required(),
        }),
      })
      .when('type', {
        is: ConditionType.DEVICE_QUANTITY,
        then: Joi.object({
          minDevices: Joi.number().min(0).max(10000).required(),
          maxDevices: Joi.number().min(0).max(10000).optional(),
        }),
      })
      .or(
        'occurrenceStateNames',
        'allEventsMitigated',
        'serviceOrderTypes',
        'travelLimitReached',
        'eventType',
        'serviceOrderTags',
        'accountTags',
        'occurrenceTags',
        'deviceTags',
        'eventsFromEventTypeHasSameDevices',
        'numberOfEventsFromEventType',
        'partitionStatus',
        'allPartitions',
        'minDevices',
        'maxDevices',
        'serviceType',
        'imageDetections',
      )
      .required(),
  }),
}).required()

export const branchSchema = Joi.object<AggregatedBranch>({
  index: Joi.number().required(),
  branchOperation: Joi.string()
    .valid(...Object.values(BranchOperation))
    .required(),
  conditionsOperation: Joi.string()
    .valid(...Object.values(BranchOperation))
    .required(),
  conditions: Joi.array().items(conditionSchema).min(1).required(),
})

export const conditionsSchema = Joi.object({
  conditions: Joi.array()
    .items(conditionSchema.append({ id: Joi.number(), formType: Joi.string() }))
    .min(1),
})

export const branchesSchema = Joi.object({
  branches: Joi.array().items(branchSchema),
})

const soundSchema = Joi.object({
  type: Joi.string().valid(...Object.values(NotificationType)),
  soundId: Joi.string().uuid(),
  soundName: Joi.string(),
})

export const actionSchema = Joi.object<Action>({
  index: Joi.number().required(),
  type: Joi.string()
    .valid(...Object.values(ActionType))
    .required(),
  parameters: Joi.object({
    occurrenceStateName: Joi.string()
      .valid(...Object.values(actionOccurrenceStateName))
      .optional(),
    occurrencePriority: Joi.number().min(0).max(100000).optional(),
    sendNotification: soundSchema.optional(),
    phoneCallTarget: Joi.string()
      .valid(...Object.values(phoneCallTarget))
      .optional(),
    stringifiedPayload: Joi.custom((value, helpers) => {
      const valid = validateJSON(value)

      if (!valid) return helpers.error('any.required')

      return value
    }).optional(),
    occurrenceTitle: Joi.string().max(45).allow('', null).optional(),
    allowFinishByDuty: Joi.boolean().optional(),
  })
    .or(
      'occurrenceStateName',
      'occurrencePriority',
      'sendNotification',
      'phoneCallTarget',
      'stringifiedPayload',
      'occurrenceTitle',
      'allowFinishByDuty',
    )
    .required(),
})

export const actionsSchema = Joi.object({
  actions: Joi.array().items(actionSchema),
})

export const rulesSchema = Joi.object<AggregatedRule>({
  name: Joi.string()
    .required()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]:
        'Por favor preencha este campo para continuar',
      [StringSchemeErrorsEnum.REQUIRED]:
        'Por favor preencha este campo para continuar',
    }),
  index: Joi.number().optional(),
  trigger: triggerSchema.required(),
  branches: Joi.array().items(branchSchema),
  actions: Joi.array().items(actionSchema).required().min(1),
})

export const coverageSchema = Joi.object<CoverageGrouper>({
  customers: Joi.array().items(fragmentSchema),
  accounts: Joi.array().items(accountCoverageSchema),
})

const parametersSchema = Joi.object<Parameters>({
  phoneCallToPoliceEnabled: Joi.boolean().required(),
  vehicleTravelEnabled: Joi.boolean().required(),
  finishOccurrenceWithoutPhoneCallEnabled: Joi.boolean().required(),
  finishOccurrenceByDutyEnabled: Joi.boolean().required(),
})

export const attendancePolicySchema =
  Joi.object<AggregatedAttendPolicyResponse>({
    id: Joi.string().uuid().optional(),
    active: Joi.boolean().optional(),
    name: Joi.string().required(),
    occurrencePriority: Joi.number().min(0).max(1000).required(),
    occurrenceTitle: Joi.string().max(45).allow('', null).optional(),
    parameters: parametersSchema.required(),
    serviceType: fragmentSchema.required(),
    occurrenceType: fragmentSchema.required(),
    coverages: coverageSchema.optional(),
    rules: Joi.array().items(rulesSchema).min(1).required(),
  })
