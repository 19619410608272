import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import PartitionDriver from 'services/partition'
import { PartitionResponse } from 'services/partition/types'

import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'

type UseGetPartitions = {
  partitions: PartitionResponse[] | undefined
  isFetching: boolean
  isError: boolean
  fetchNextPartitionsPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getPartitions = async (accountIds: string, code?: string, offset = 0) => {
  const response = await PartitionDriver.queryPartitions({
    recordsPerPage: 15,
    offset,
    accountIds,
    ...(code && { code }),
  })
  return response
}

export function useGetPartitions(
  accountIds: string,
  code?: string,
  enabled = true,
): UseGetPartitions {
  const {
    isError,
    data,
    isFetching,
    fetchNextPage: fetchNextPartitionsPage,
  } = useInfiniteQuery({
    queryKey: ['account-partitions', accountIds, code],
    queryFn: ({ pageParam }) => getPartitions(accountIds, code, pageParam),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<PartitionResponse>(lastPage, allPages),
    refetchOnWindowFocus: false,
    enabled,
  })

  const partitions = useMemo(
    () => flattenPages<PartitionResponse>(data),
    [data],
  )

  return {
    isError,
    partitions,
    fetchNextPartitionsPage,
    isFetching,
  }
}
