import { useMemo, useState } from 'react'

import { Droplist, Icon, IconButton, Tag } from 'components'

import {
  OccurrenceColumnTitle,
  OccurrenceListByCoverage,
  OccurrenceTravel,
} from 'services/occurrence/types'
import { OccurrenceCardActions } from 'domains/occurrence/types'
import { OccurrenceTravelIcon } from 'domains/occurrence/components'
import { getIntervalInHours } from 'domains/occurrence/utilities/date'
import { occurrenceTypesLabel } from 'domains/occurrence/screens/Attendance/utils'
import { getBorderStyle } from 'domains/occurrence/utilities/occurrenceCard/occurrenceCard'
import { IconWithTooltip } from 'domains/occurrence/components/IconWithTooltip/IconWithTooltip'

import joinClassNames from 'utilities/joinClassNames'
import { dateNowWithSeconds, formatDecimal } from 'utilities/date'

import styles from './OccurrenceCard.module.scss'

type OccurrenceCardProps = Pick<
  OccurrenceListByCoverage,
  | 'id'
  | 'account'
  | 'operator'
  | 'finishedAt'
  | 'customer'
  | 'totalEvents'
  | 'number'
  | 'stateName'
  | 'title'
  | 'tactical'
  | 'alerts'
  | 'patrimony'
> & {
  onSelected: (id: string) => void
  createdAt?: number
  typeName: OccurrenceListByCoverage['type']['name']
  actions: OccurrenceCardActions[]
  columnTitle: OccurrenceColumnTitle
  selectedOccurrenceId: string
}

export const OccurrenceCard = ({
  id,
  onSelected,
  totalEvents,
  typeName,
  stateName,
  title,
  account,
  actions,
  columnTitle,
  operator,
  tactical,
  alerts,
  patrimony,
  customer,
  createdAt,
  selectedOccurrenceId,
}: OccurrenceCardProps) => {
  const hasAlerts = useMemo(() => {
    return Object.values(alerts).some((alert) => alert.status)
  }, [alerts])

  const overdueTime = useMemo(() => {
    if (!createdAt) return 0

    return getIntervalInHours(createdAt)
  }, [createdAt])

  const MIN_AMOUNT_EVENTS = 1

  const selected = id === selectedOccurrenceId

  const [activeSubMenu, setActiveSubMenu] = useState(false)

  const showTravelStatus = useMemo(
    () =>
      [
        'TRAVEL_SENT',
        'TRAVEL_VIEWED',
        'TRAVEL_STARTED',
        'TRAVEL_FINISHED',
        'FINISHED',
      ].includes(stateName),
    [stateName],
  )

  const serviceType = patrimony?.serviceType?.name
  const serviceTypeColor = patrimony?.serviceType?.color

  return (
    <div
      onClick={() => onSelected(id)}
      className={joinClassNames(
        styles.container,
        styles[typeName],
        !!overdueTime && stateName === 'AVAILABLE' && styles.isOverdue,
        hasAlerts && styles.isAlert,
        selected && styles.selected,
      )}
    >
      <span className={styles.typeNameBorder} />
      <div>
        <div className={styles.header}>
          <small className={styles.typeName}>
            {occurrenceTypesLabel[typeName].toUpperCase()} {title && `- ${title}`}
          </small>

          <div className={styles.iconsBox}>
            <div className={styles.icons}>
              {totalEvents > MIN_AMOUNT_EVENTS && (
                <div className={styles.events}>
                  <Icon name="event" color="element" width={12} />
                  <span>{formatDecimal(totalEvents)}</span>
                </div>
              )}
            </div>
            <div className={styles.icons}>
              <div className={styles.iconWithTooltip}>
                <IconWithTooltip
                  Icon={<Icon name="duration" color="element" width={12} />}
                  tooltipInfo={dateNowWithSeconds(createdAt)}
                />
              </div>
              {alerts.checkList.status && (
                <div className={styles.iconWithTooltip}>
                  <IconWithTooltip
                    Icon={<Icon name="checklist" color="danger" width={12} />}
                    tooltipInfo={alerts.checkList.name}
                  />
                </div>
              )}
              {alerts.coordinatesUpdateRequest.status && (
                <div className={styles.iconWithTooltip}>
                  <IconWithTooltip
                    Icon={
                      <Icon name="coordinate-update" color="danger" width={12} />
                    }
                    tooltipInfo={alerts.coordinatesUpdateRequest.name}
                  />
                </div>
              )}
              {alerts.supportRequest.status && (
                <div className={styles.iconWithTooltip}>
                  <IconWithTooltip
                    Icon={
                      <Icon name="support-request" color="danger" width={12} />
                    }
                    tooltipInfo={alerts.supportRequest.name}
                  />
                </div>
              )}
              {alerts.tactical.status && (
                <div className={styles.iconWithTooltip}>
                  <IconWithTooltip
                    Icon={<Icon name="timeout" color="danger" width={12} />}
                    tooltipInfo={alerts.tactical.name}
                  />
                </div>
              )}
              {Boolean(overdueTime && stateName === 'AVAILABLE') && (
                <div className={styles.iconWithTooltip}>
                  <IconWithTooltip
                    Icon={<Icon name="timeout" color="danger" width={12} />}
                    tooltipInfo={`Aberta há ${overdueTime}h`}
                  />
                </div>
              )}
            </div>
            <div className={styles.icons}>
              <Droplist
                trigger={
                  <IconButton size="sm" aria-label="opções">
                    <Icon name="menu-kebab" color="element" />
                  </IconButton>
                }
              >
                <div className={styles.dropList}>
                  {actions.map(
                    ({ label, handler, isVisible, isDisabled, subActions }) => {
                      const isActionVisible = isVisible ? isVisible?.(id) : true
                      const isActionDisabled = isDisabled
                        ? isDisabled?.(id)
                        : false
                      const borderStyle = getBorderStyle(columnTitle, label)

                      if (isActionVisible) {
                        return (
                          <div
                            key={label}
                            className={joinClassNames(
                              styles.dropListItemContainer,
                              styles[borderStyle],
                            )}
                            onMouseLeave={() => setActiveSubMenu(false)}
                          >
                            <button
                              className={[
                                styles.dropListItem,
                                isActionDisabled && styles.disabled,
                              ].join(' ')}
                              onMouseEnter={() => {
                                if (subActions && subActions.length) {
                                  setActiveSubMenu(true)
                                }
                              }}
                              disabled={isActionDisabled}
                              onClick={(e) => {
                                handler?.(id)
                                e.stopPropagation()
                              }}
                            >
                              <span>{label}</span>
                              {subActions && (
                                <Icon
                                  name="chevron-sm-right"
                                  color="element"
                                  width={12}
                                />
                              )}
                            </button>
                            {activeSubMenu &&
                            subActions &&
                            subActions.length ? (
                              <div className={styles.subMenu}>
                                {subActions.map(
                                  ({
                                    label: subLabel,
                                    handler: subHandler,
                                  }) => (
                                    <button
                                      key={subLabel}
                                      className={styles.dropListSubItem}
                                      onClick={(e) => {
                                        subHandler?.(id)
                                        e.stopPropagation()
                                      }}
                                    >
                                      {subLabel}
                                    </button>
                                  ),
                                )}
                              </div>
                            ) : null}
                          </div>
                        )
                      } else {
                        return <div key={label} />
                      }
                    },
                  )}
                </div>
              </Droplist>
            </div>
          </div>
        </div>
        <strong>
          {account?.code} - {customer?.name}
        </strong>
        {!!serviceType && (
          <Tag
            color="green"
            {...(serviceTypeColor && {
              style: {
                backgroundColor: serviceTypeColor,
                color: serviceTypeColor,
              },
            })}
            singleColor={!!serviceTypeColor}
          >
            {serviceType}
          </Tag>
        )}

        {!!tactical && (showTravelStatus || stateName === 'AVAILABLE') && (
          <div className={styles.tactical}>
            <div>
              <OccurrenceTravelIcon
                travelStatus={stateName as OccurrenceTravel}
                alert={hasAlerts}
                checklistFailure={alerts.checkList.status}
                selected={selected}
              />
            </div>
            <div>
              <p>{tactical?.agent?.name}</p>
              {!!tactical?.vehicle?.licensePlate && (
                <p>Veículo {tactical?.vehicle?.licensePlate}</p>
              )}
            </div>
          </div>
        )}
        {stateName === 'IN_PROGRESS' && (
          <div className={styles.footer}>
            {operator && <span>{operator?.name}</span>}
          </div>
        )}
      </div>
    </div>
  )
}
