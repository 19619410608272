import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { getNextPageOffSet, flattenPages } from '../utilities/pagination'

import { SortedCustomer } from 'services/customer/types'
import { CustomerDriver } from 'services/customer'

interface UseGetCustomersReturn {
  isError: boolean
  isFetching: boolean
  customers: SortedCustomer[]
  fetchNextCustomersPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

async function getCustomers(
  offset = 0,
  name?: string,
  document?: string,
  secondName?: string,
) {
  const response = await CustomerDriver.querySortedCustomers({
    recordsPerPage: 15,
    offset,
    ...(name && { name }),
    ...(document && { document }),
    ...(secondName && { secondName }),
    active: true,
  })

  return response
}

export async function getCustomer(searchFilter: string, offset = 0) {
  const response = await CustomerDriver.querySortedCustomers({
    offset,
    recordsPerPage: 15,
    ...(searchFilter && { name: searchFilter }),
    active: true,
  })

  return response.data
}

export const useGetCustomers = (
  name?: string,
  document?: string,
  secondName?: string,
  enabled = false,
): UseGetCustomersReturn => {
  const {
    isError,
    data,
    fetchNextPage: fetchNextCustomersPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['customers', name, document, secondName],
    initialPageParam: 0,
    queryFn: ({ pageParam }) =>
      getCustomers(pageParam, name, document, secondName),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<SortedCustomer>(lastPage, allPages),
    refetchOnMount: false,

    enabled,
  })

  const customers = useMemo(() => {
    return flattenPages<SortedCustomer>(data)
  }, [data])

  return { isError, isFetching, customers, fetchNextCustomersPage }
}
