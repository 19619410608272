import dayjs from 'dayjs'

import { ServiceOrderHistory } from 'services/serviceOrder/types'
import { getServiceOrderStatusData } from '../../utilities'

import { translateServiceOrderLabelType } from 'services/serviceOrderLabel/types'
import styles from './History.module.scss'

interface HistoryProps {
  serviceOrderHistory: ServiceOrderHistory[]
  titleClassName?: string
}

export function History({
  serviceOrderHistory,
  titleClassName,
}: HistoryProps): JSX.Element {
  return (
    <div className={styles.historyContainer}>
      {serviceOrderHistory.map((history) => {
        const { status } = getServiceOrderStatusData(history.status)
        return (
          <div key={history.datetime} className={styles.historyItem}>
            <p
              className={[styles.historyTitle, titleClassName || ''].join(' ')}
            >
              {status} - {dayjs(history.datetime).format('DD/MM/YY HH:mm')}
              {history.user ? ` - ${history.user.name}` : ''}
            </p>
            <p className={styles.historyContent}>{history.note}</p>
            {history.labels
              ?.sort((a, b) => a.description.localeCompare(b.description))
              .map((label) => (
                <p key={label.id} className={styles.labels}>
                  <strong>
                    {translateServiceOrderLabelType[label.type]}:{' '}
                  </strong>
                  <span>{label.description}</span>
                </p>
              ))}
          </div>
        )
      })}
    </div>
  )
}
