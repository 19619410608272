import { ReactNode, useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { FormProvider, useForm } from 'react-hook-form'

import {
  AttendancePolicyBaseForm,
  CoverageForm,
} from 'domains/attendancePolicy/components'

import {
  AggregatedAttendPolicyResponse,
  AggregatedRule,
  AttendancePolicyPayload,
} from 'services/attendancePolicy/types'
import { joiResolver } from '@hookform/resolvers/joi'
import { attendancePolicySchema } from 'domains/attendancePolicy/schema'

import { RuleForm } from 'domains/attendancePolicy/components/RuleForm/RuleForm'

import { OccurrenceTypeTitle } from 'domains/occurrence/data/occurrence'
import { OccurrenceTypeName } from 'services/occurrence/types'

import { formKeys } from 'domains/attendancePolicy/components/AttendPolicyFormGroup/types'

import styles from './AttendPolicyFormGroup.module.scss'

type VisibleForm = {
  formKey: formKeys
  ruleData?: AggregatedRule
}

type AttendancePolicyGroupFormProps = {
  attendancePolicy?: AggregatedAttendPolicyResponse
  onSubmit: (data: AttendancePolicyPayload) => void
}

export const AttendPolicyFormGroup = ({
  attendancePolicy,
  onSubmit,
}: AttendancePolicyGroupFormProps) => {
  const [visibleForm, setVisibleForm] = useState<VisibleForm>({
    formKey: 'POLICY_BASE',
  })

  const navigate = useNavigate()

  const form = useForm<AggregatedAttendPolicyResponse>({
    resolver: joiResolver(attendancePolicySchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      id: attendancePolicy?.id,
      active: attendancePolicy?.active,
      name: attendancePolicy?.name,
      ...(attendancePolicy?.occurrenceType && {
        occurrenceType: {
          id: attendancePolicy?.occurrenceType.id,
          name: OccurrenceTypeTitle[
            attendancePolicy.occurrenceType.name as OccurrenceTypeName
          ],
        },
      }),
      occurrencePriority: attendancePolicy?.occurrencePriority || 0,
      occurrenceTitle: attendancePolicy?.occurrenceTitle || '',
      serviceType: attendancePolicy?.serviceType,
      parameters: attendancePolicy?.parameters || {
        phoneCallToPoliceEnabled: false,
        vehicleTravelEnabled: false,
        finishOccurrenceWithoutPhoneCallEnabled: false,
      },
      coverages: attendancePolicy?.coverages || {
        customers: [],
        accounts: [],
      },
      rules:
        attendancePolicy?.rules?.map((rule) => ({
          ...rule,
          branches: rule.branches
            .sort((prev, next) => prev.index - next.index)
            .map((branch) => ({
              ...branch,
              conditions: branch.conditions.map((condition) => ({
                ...condition,
                evaluation: condition.evaluation ? 'HAS' : 'HASNT',
              })),
            })),
        })) || [],
    },
  })

  const { register, setValue, handleSubmit, watch, trigger } = form

  const rules = watch('rules')

  const submit = useCallback(() => {
    const attendancePolicy = form.getValues()
    const request: AttendancePolicyPayload = {
      name: attendancePolicy.name,
      active: attendancePolicy.active,
      occurrenceTypeId: attendancePolicy.occurrenceType.id,
      occurrenceTitle: attendancePolicy.occurrenceTitle,
      serviceTypeId: attendancePolicy.serviceType.id,
      occurrencePriority: attendancePolicy.occurrencePriority,
      parameters: attendancePolicy.parameters,
      selectors: {
        customerIds: attendancePolicy.coverages.customers.map(
          (customer) => customer.id,
        ),
        accountIds: attendancePolicy.coverages.accounts.map(
          (account) => account.id,
        ),
      },
      rules: attendancePolicy.rules.map((rule) => {
        const { trigger, branches, actions } = rule

        return {
          index: rule.index,
          name: rule.name,
          trigger: {
            type: trigger.type,
            parameters: {
              ...(trigger.parameters.eventType && {
                eventTypeIds: trigger.parameters.eventType.map(
                  (eventType) => eventType.id,
                ),
              }),
              ...(trigger.parameters.occurrenceTypeId && {
                occurrenceTypeId: attendancePolicy.occurrenceType.id,
              }),
              sensorTypes: trigger.parameters.sensorTypes,
              timeInterval: trigger.parameters.timeInterval,
              timeUnit: trigger.parameters.timeUnit,
              occurrenceState: trigger.parameters.occurrenceState,
              interventionType: trigger.parameters.interventionType,
            },
          },
          branches: branches.map((branch) => {
            return {
              index: branch.index,
              branchOperation: branch.branchOperation,
              conditionsOperation: branch.conditionsOperation,
              conditions: branch.conditions.map((condition) => {
                const { facts } = condition

                return {
                  type: condition.type,
                  evaluation: condition.evaluation
                    ? condition.evaluation === 'HAS'
                    : true,
                  facts: {
                    ...(facts.eventType && {
                      eventTypeId: facts.eventType.id,
                    }),
                    ...(facts.accountTags && {
                      tagIds: facts.accountTags.map(
                        (accountTag) => accountTag.id,
                      ),
                    }),
                    ...(facts.occurrenceTags && {
                      tagIds: facts.occurrenceTags.map(
                        (occurrenceTag) => occurrenceTag.id,
                      ),
                    }),
                    ...(facts.deviceTags && {
                      tagIds: facts.deviceTags.map((deviceTag) => deviceTag.id),
                    }),
                    ...(facts.serviceOrderTags && {
                      tagIds: facts.serviceOrderTags.map(
                        (serviceOrderTag) => serviceOrderTag.id,
                      ),
                    }),
                    occurrenceStateNames: facts.occurrenceStateNames,
                    allEventsMitigated: facts.allEventsMitigated,
                    serviceOrderTypes: facts.serviceOrderTypes,
                    travelLimitReached: facts.travelLimitReached,
                    numberOfEventsFromEventType:
                      facts.numberOfEventsFromEventType,
                    eventsFromEventTypeHasSameDevices:
                      facts.eventsFromEventTypeHasSameDevices,
                    allPartitions: facts.allPartitions,
                    partitionStatus: facts.partitionStatus,
                    minDevices: facts.minDevices,
                    maxDevices: facts.maxDevices,
                    serviceTypeId: facts.serviceType?.id,
                    imageDetections: facts.imageDetections,
                  },
                }
              }),
            }
          }),
          actions: actions.map((action) => {
            const { parameters } = action

            return {
              index: action.index,
              type: action.type,
              parameters: {
                occurrenceStateName: parameters.occurrenceStateName,
                occurrencePriority: parameters.occurrencePriority,
                soundId: parameters.sendNotification?.soundId,
                type: parameters.sendNotification?.type,
                phoneCallTarget: parameters.phoneCallTarget,
                ...(!!parameters?.stringifiedPayload && {
                  payload: JSON.parse(parameters.stringifiedPayload),
                }),
                occurrenceTitle: parameters.occurrenceTitle,
                allowFinishByDuty: parameters.allowFinishByDuty,
              },
            }
          }),
        }
      }),
    }

    onSubmit(request)
  }, [form, onSubmit])

  const handleSelectedRule = useCallback(
    (ruleIndex: string) => {
      const selectedRule = rules.find(
        (rule) => String(rule.index) === ruleIndex,
      )

      return selectedRule
    },
    [rules],
  )

  const navigateToBaseForm = useCallback(() => {
    setVisibleForm({ formKey: 'POLICY_BASE' })
  }, [])

  const handleNavigateBaseForm = useCallback(
    (formName: formKeys, ruleIndex?: string) => {
      if (ruleIndex) {
        setVisibleForm({
          formKey: formName,
          ruleData: handleSelectedRule(ruleIndex),
        })

        return
      }

      setVisibleForm({ formKey: formName })
    },
    [handleSelectedRule],
  )

  const handleRenderForms = useCallback(
    ({ formKey, ruleData }: VisibleForm) => {
      const forms: Record<formKeys, ReactNode> = {
        POLICY_BASE: (
          <AttendancePolicyBaseForm
            onGoBack={() => navigate(-1)}
            goToForm={handleNavigateBaseForm}
          />
        ),
        COVERAGE: <CoverageForm onNavigate={navigateToBaseForm} />,
        RULE: <RuleForm onNavigate={navigateToBaseForm} rule={ruleData} />,
      }

      return forms[formKey]
    },
    [handleNavigateBaseForm, navigateToBaseForm, navigate],
  )

  useEffect(() => {
    register('id')
    register('name')
    register('active')
    register('parameters')
    register('occurrenceType')
    register('occurrencePriority')
    register('serviceType')
    register('coverages')
    register('rules')

    trigger()
  }, [setValue, register, trigger])

  return (
    <FormProvider {...form}>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(submit)}>
          {handleRenderForms(visibleForm)}
        </form>
      </div>
    </FormProvider>
  )
}
