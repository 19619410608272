import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ToastProvider, UserInfoProvider, useStorage } from 'shared/hooks'

import './App.css'
import Routes from './routes/Routes'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      placeholderData: (prev: unknown) => prev,
    },
    mutations: {
      retry: false,
    },
  },
})

const App = () => {
  useStorage()

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <UserInfoProvider>
          <ToastProvider>
            <Routes />
          </ToastProvider>
        </UserInfoProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
