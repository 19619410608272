import { ComponentProps } from 'react'

import { Colors } from '../types'
import styles from './styles.module.scss'

interface TagProps extends ComponentProps<'div'> {
  color?: Colors | 'element' | 'inverse' | 'outlined'
  singleColor?: boolean
}

const Tag = ({
  className,
  singleColor = false,
  color = 'brand',
  ...props
}: TagProps) => (
  <div
    {...props}
    className={[
      styles.tag,
      styles[color],
      singleColor && styles.singleColor,
      className,
    ].join(' ')}
  />
)

export default Tag
