import { useCallback, useEffect, useMemo } from 'react'

import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg'
import { ReactComponent as BackIcon } from 'assets/svg/back.svg'
import { ReactComponent as PlusSign } from 'domains/attendancePolicy/assets/svg/plusSign.svg'

import { Accordion, Button } from 'components'
import { RuleActionFormComponent } from 'domains/attendancePolicy/types'

import { Action, ActionType } from 'services/attendancePolicy/types'

import { AccordionAction } from 'domains/attendancePolicy/components/RuleForm/types'

import { actionOptions } from 'domains/attendancePolicy/data/action'

import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { actionsSchema } from 'domains/attendancePolicy/schema'
import {
  NotifyForm,
  PriorityForm,
  StateChangeForm,
  RequiredPhoneCall,
  Payload,
  OccurrenceTitle,
  ChangeFinishRestriction,
} from 'domains/attendancePolicy/components/ActionForms'

import { getActionAccordionTitle } from 'domains/attendancePolicy/components/RuleForm/utilities'

import styles from './AccordionForm.module.scss'

interface AccordionCardProps {
  actionList: Action[]
  onSubmit: (actions: Action[]) => void
}

export const AccordionForm = ({ actionList, onSubmit }: AccordionCardProps) => {
  const form = useForm<{ actions: AccordionAction[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(actionsSchema),
    defaultValues: {
      actions: actionList.length ? actionList : [{ index: 1 }],
    },
  })

  const { register, watch, setValue, formState, trigger } = form

  const actions = watch('actions')

  const ruleFormComponents: RuleActionFormComponent = useMemo(
    () => ({
      [ActionType.CHANGE_OCCURRENCE_STATE]: StateChangeForm,
      [ActionType.CHANGE_OCCURRENCE_PRIORITY]: PriorityForm,
      [ActionType.SEND_NOTIFICATION]: NotifyForm,
      [ActionType.REQUIRE_PHONE_CALL]: RequiredPhoneCall,
      [ActionType.SEND_OCCURRENCE_CUSTOM_EVENT]: Payload,
      [ActionType.CHANGE_OCCURRENCE_TITLE]: OccurrenceTitle,
      [ActionType.CHANGE_FINISH_RESTRICTION]: ChangeFinishRestriction,
    }),
    [],
  )

  const renderFormComponent = useCallback(
    (action: AccordionAction) => {
      if (action.type) {
        const Form = ruleFormComponents[action.type]

        return (
          <FormProvider {...form}>
            <Form
              data={action}
              onChange={(changedAction) => {
                const updatedAction = [...actions]
                updatedAction[action.index - 1] = {
                  ...action,
                  parameters: changedAction.parameters,
                }

                setValue('actions', updatedAction, { shouldValidate: true })
              }}
            />
          </FormProvider>
        )
      }
    },
    [ruleFormComponents, form, actions, setValue],
  )

  const onSave = useCallback(() => {
    const normalizedActions: Action[] = actions.map((action) => ({
      index: action.index,
      parameters: action.parameters || {},
      type: action.type || ActionType.CHANGE_OCCURRENCE_STATE,
    }))

    onSubmit(normalizedActions)
  }, [actions, onSubmit])

  useEffect(() => {
    register('actions')

    trigger()
  }, [register, trigger])

  return (
    <>
      <main className={styles.accordionWrapper}>
        {actions
          .sort((prev, next) => prev.index - next.index)
          .map((action, index) => (
            <div key={index}>
              <div className={styles.container}>
                <Accordion.Root>
                  <Accordion.Item className={styles.item}>
                    {({ isOpen }) => (
                      <>
                        <Accordion.Header>
                          <div>
                            <Accordion.Trigger className={styles.trigger}>
                              <div>
                                {!!action.type && !!action.parameters ? (
                                  <div className={styles.headerDescription}>
                                    {`Ação ${index + 1}`}
                                    {!isOpen && (
                                      <>
                                        :
                                        <span className={styles.actionType}>
                                          {getActionAccordionTitle({
                                            type: action.type,
                                            parameters: action.parameters,
                                          })}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <> Selecione o tipo de ação</>
                                )}
                              </div>
                              <Accordion.Icon />
                            </Accordion.Trigger>
                          </div>
                          {!!action.type && !!action.parameters && (
                            <>
                              <div className={styles.header}>
                                <div className={styles.endHeader}>
                                  <button
                                    onClick={() => {
                                      const updatedActions = [...actions]

                                      updatedActions[action.index - 1] = {
                                        index: action.index,
                                      }

                                      setValue('actions', updatedActions, {
                                        shouldValidate: true,
                                      })
                                    }}
                                  >
                                    <BackIcon className={styles.hoverIcon} />
                                  </button>
                                  <TrashIcon
                                    className={[
                                      styles.icon,
                                      styles.hoverIcon,
                                    ].join(' ')}
                                    onClick={() => {
                                      actions.length !== 1 &&
                                        setValue(
                                          'actions',
                                          actions
                                            .filter(
                                              (prevAction) =>
                                                prevAction.index !==
                                                action.index,
                                            )
                                            .map((item, index) => ({
                                              ...item,
                                              index: index + 1,
                                            })),
                                        )
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </Accordion.Header>

                        <Accordion.Content className={styles.innerContent}>
                          {!action.type && (
                            <>
                              <div className={styles.optionContainer}>
                                {actionOptions?.map((option) => (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      const updatedActions = [...actions]
                                      updatedActions[index] = {
                                        index: action.index,
                                        type: option.formType,
                                        parameters: {},
                                      }

                                      setValue('actions', updatedActions, {
                                        shouldValidate: true,
                                      })
                                    }}
                                    key={option.id}
                                    className={styles.option}
                                  >
                                    <option.icon
                                      className={styles.optionIcon}
                                    />
                                    <h2>{option.title}</h2>
                                    <p>{option.description}</p>
                                  </button>
                                ))}
                              </div>
                            </>
                          )}

                          {!!action.type && !!action.parameters && (
                            <div>{renderFormComponent(action)}</div>
                          )}
                        </Accordion.Content>
                      </>
                    )}
                  </Accordion.Item>
                </Accordion.Root>
              </div>
              <div key={action.index}>
                {index !== actions.length - 1 && (
                  <div data-text={'E'} className={styles.spacer} />
                )}
              </div>
            </div>
          ))}

        <button
          type="button"
          onClick={() => {
            setValue('actions', [...actions, { index: actions.length + 1 }], {
              shouldValidate: true,
            })
          }}
          className={styles.button}
        >
          <PlusSign />
          {`Adicionar nova ação`}
        </button>

        <div className={styles.footer}>
          <Button
            buttonTitle="Salvar ação"
            onClick={onSave}
            disabled={!formState.isValid}
            type="secondary"
            width="172px"
          />
        </div>
      </main>
    </>
  )
}
