import {
  Filter,
  ActionPlan,
  ChangeState,
  AccountNotes,
  AutomaticRule,
  ImageUploader,
  AccountHistory,
  ChangePartition,
  FinishOccurrence,
  IntrusionConfirmed,
  FinishedExternally,
  InterventionJustification,
  InterventionWithoutNote,
} from './components'

import { ModalProps, Modals } from '../../types'
import { useModal } from '../../context'
import { Gallery } from 'components'

const handleRenderModal = (modal: Modals, data: ModalProps) => {
  const modals: Record<Modals, React.ReactNode> = {
    FILTER: <Filter />,
    INTERVENTION_JUSTIFICATION: !!data.INTERVENTION_JUSTIFICATION && (
      <InterventionJustification {...data.INTERVENTION_JUSTIFICATION} />
    ),
    ACTION_PLAN: <ActionPlan />,
    AUTOMATIC_RULE: !!data.AUTOMATIC_RULE && (
      <AutomaticRule {...data.AUTOMATIC_RULE} />
    ),
    IMAGE_UPLOADER: <ImageUploader />,
    ACCOUNT_HISTORY: !!data.ACCOUNT_HISTORY && (
      <AccountHistory {...data.ACCOUNT_HISTORY} />
    ),
    FINISH_OCCURRENCE: <FinishOccurrence />,
    FINISHED_EXTERNALLY: <FinishedExternally />,
    INTRUSION_CONFIRMED: <IntrusionConfirmed />,
    ACCOUNT_NOTES: !!data.ACCOUNT_NOTES && (
      <AccountNotes {...data.ACCOUNT_NOTES} />
    ),
    CHANGE_PARTITION: !!data.CHANGE_PARTITION && (
      <ChangePartition {...data.CHANGE_PARTITION} />
    ),
    GALLERY: !!data.GALLERY && <Gallery {...data.GALLERY} />,
    INTERVENTION_WITHOUT_NOTE: <InterventionWithoutNote />,
    CHANGE_STATE: !!data.CHANGE_STATE && <ChangeState {...data.CHANGE_STATE} />,
  }

  return modals[modal]
}

const ModalsContainer = () => {
  const { modal, data } = useModal()

  return <>{modal && data && handleRenderModal(modal, data)}</>
}

export default ModalsContainer
