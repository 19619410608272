import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosInstance } from 'axios'
import { Result, TagOutput } from 'services/types'
import HttpClient from '../httpClient'
import { endpoints } from './endpoints'

import {
  OccurrenceListResponse,
  OccurrenceQuery,
  InterventionPayload,
  OccurrenceResponse,
  OccurrenceInterventionNotePayload,
  AggregatedOccurrenceResponse,
  OccurrenceTypeQueryResponse,
  OccurrenceTypeQuery,
  OccurrenceAccountResponse,
  OccurrenceByUserCoverageQuery,
  OccurrenceListByCoverage,
  OccurrenceTagQueryPayload,
  AgentDuty,
  ManualOccurrencePayload,
} from './types'
import { ImageResponse } from 'services/image/types'
import buildUrl from 'utilities/buildUrl'

export interface OccurrenceDriver {
  findAttendance(id: string): Promise<AggregatedOccurrenceResponse>
  createIntervention(
    occurrenceId: string,
    Intervention: InterventionPayload,
  ): Promise<void>
  createInterventionNote(
    occurrenceId: string,
    interventionId: string,
    payload: OccurrenceInterventionNotePayload,
  ): Promise<void>
  findAllOccurrences(
    query: OccurrenceQuery,
  ): Promise<Result<OccurrenceListResponse>>
  queryOccurrenceType(
    query?: OccurrenceTypeQuery,
  ): Promise<Result<OccurrenceTypeQueryResponse>>
  getNextByUser(userId: string): Promise<OccurrenceResponse>
  attendByUser(
    occurrenceId: string,
    userId: string,
  ): Promise<OccurrenceResponse>
  getOccurrenceAccount(occurrenceId: string): Promise<OccurrenceAccountResponse>
  queryOccurrenceTags: (
    payload: OccurrenceTagQueryPayload,
  ) => Promise<Result<TagOutput>>
}

class OccurrenceDriverImpl implements OccurrenceDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async getOccurrenceAccount(
    occurrenceId: string,
  ): Promise<OccurrenceAccountResponse> {
    const result = await this.httpClient.get<OccurrenceAccountResponse>(
      `/occurrence/${occurrenceId}/account`,
    )
    return result.data
  }

  public async queryOccurrenceType(query?: OccurrenceTypeQuery) {
    const result = await this.httpClient.get<
      Promise<Result<OccurrenceTypeQueryResponse>>
    >(`/occurrence/occurrenceType`, {
      params: query,
    })

    return result.data
  }

  public async findAttendance(id: string) {
    const result = await this.httpClient.get<AggregatedOccurrenceResponse>(
      `/occurrence/${id}/find`,
    )

    return result.data
  }

  public async createIntervention(
    occurrenceId: string,
    intervention: InterventionPayload,
  ): Promise<void> {
    const payload = {
      ...intervention,
    }

    await this.httpClient.post(
      `/occurrence/${occurrenceId}/intervention`,
      payload,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': `multipart/form-data`,
        },
      },
    )
  }

  public async createInterventionNote(
    occurrenceId: string,
    interventionId: string,
    payload: OccurrenceInterventionNotePayload,
  ): Promise<void> {
    return this.httpClient.post(
      `/occurrence/${occurrenceId}/intervention/${interventionId}/note`,
      payload,
    )
  }

  public async findAllOccurrences(
    query: OccurrenceQuery,
  ): Promise<Result<OccurrenceListResponse>> {
    const result = await this.httpClient.get<Result<OccurrenceListResponse>>(
      '/occurrence',
      { params: query },
    )
    return result.data
  }

  public async findAllOccurrencesByCoverage(
    query: OccurrenceByUserCoverageQuery,
  ): Promise<Result<OccurrenceListByCoverage>> {
    const result = await this.httpClient.get<Result<OccurrenceListByCoverage>>(
      '/occurrence/getByUserCoverageForManagement',
      { params: query },
    )
    return result.data
  }

  public async getNextByUser(userId: string): Promise<OccurrenceResponse> {
    const result = await this.httpClient.get<OccurrenceResponse>(
      `/occurrence/getNextByUser/${userId}`,
    )
    return result.data
  }

  public async attendByUser(
    occurrenceId: string,
    userId: string,
  ): Promise<OccurrenceResponse> {
    const result = await this.httpClient.post<OccurrenceResponse>(
      `/occurrence/${occurrenceId}/${userId}/attendByUser`,
    )
    return result.data
  }

  public async queryOccurrenceTags(
    payload: OccurrenceTagQueryPayload,
  ): Promise<Result<TagOutput>> {
    const result = await this.httpClient.get<Result<TagOutput>>(
      '/occurrence/intervention/tag',
      {
        params: payload,
      },
    )

    return result.data
  }
}

export default new OccurrenceDriverImpl()

const createManualOccurrence = async (payload: ManualOccurrencePayload) => {
  await HttpClient.post(endpoints.create, payload)
}

export const usePostManualOccurrence = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: ManualOccurrencePayload) => createManualOccurrence(data),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['occurrences-management'] })
    },
  })
}

export const useFetchTacticals = () =>
  useQuery({
    queryKey: ['fetch-occurrence-tacticals'],
    queryFn: async () => {
      const response = await HttpClient.get<{
        data: AgentDuty[]
        totalElements: number
      }>(`/agentDuty/getByUserCoverage`)

      return response.data.data
    },
  })

export const useGetOccurrenceImages = (id: string) =>
  useQuery({
    queryKey: ['fetch-occurrence-images', id],
    enabled: !!id,
    queryFn: async () => {
      const response = await HttpClient.get<ImageResponse[]>(
        buildUrl({
          route: endpoints.getImages,
          params: { id },
        }),
      )

      return response.data
    },
  })
