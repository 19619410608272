import { useMemo } from 'react'
import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'

import {
  flattenPages,
  getNextPageOffSet,
} from '../../services/utilities/pagination'

import { TagQueryResponse } from 'services/auth'
import { AccountTag, TagType } from 'services/account/types'
import { Result } from 'services/types'
import OccurrenceDriver from 'services/occurrence'

type UseGetOccurrenceTagsReturn = UseInfiniteQueryResult<
  Result<AccountTag>,
  unknown
> & {
  occurrenceTags: AccountTag[]
}

const getOccurrenceTags = async (offset = 0, name?: string) => {
  const response = await OccurrenceDriver.queryOccurrenceTags({
    size: 15,
    page: Math.round(offset / 15),
    ...(name && { name }),
  })

  const tagResponse: Result<AccountTag> = {
    data: response?.data.map((tag) => ({
      id: tag.id,
      name: tag.name,
      type: TagType.PRINCIPAL,
    })),
    totalElements: response.totalElements,
  }

  return tagResponse
}

export function useGetOccurrenceTag(
  name: string,
  enabled: boolean,
): UseGetOccurrenceTagsReturn {
  const infinityQuery = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['occurrence-tag', name],
    queryFn: ({ pageParam }) => getOccurrenceTags(pageParam, name),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<TagQueryResponse>(lastPage, allPages),
    enabled,
  })

  const occurrenceTags = useMemo(
    () => flattenPages<AccountTag>(infinityQuery?.data),
    [infinityQuery],
  )

  return {
    ...infinityQuery,
    occurrenceTags,
  }
}
