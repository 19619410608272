import { useEffect, useState } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { Combobox } from 'components/ComboboxV2/Combobox'
import { Button, ComboBoxV3, FormItem, FormLabel, Modal } from 'components'

import {
  Coordinates,
  AgentDuty,
  OccurrenceListByCoverage,
} from 'services/occurrence/types'

import styles from './ChangeTactical.module.scss'
import { fetchAgents, useGetAgentDetails } from 'services/attendance'

export interface FormProps {
  tactical: {
    id: string
    name: string
    vehicleId: string
    licensePlate: string
    coordinates: Coordinates
  }
  observation?: string
  occurrence?: string
  comment?: string
}

type ChangeTacticalState = 'FORM' | 'ERROR' | 'QUESTION'

type ChangeTacticalStateDecisions = 'ERROR' | 'QUESTION' | 'CONTINUE'

export const schema = Joi.object<FormProps>({
  tactical: Joi.object({
    id: Joi.string(),
    name: Joi.string(),
    vehicleId: Joi.string(),
    licensePlate: Joi.string(),
    coordinates: Joi.object<Coordinates>({
      latitude: Joi.number(),
      longitude: Joi.number(),
    }),
  }).required(),
  observation: Joi.string().optional(),
  comment: Joi.string().required(),
})

interface ChangeTacticalProps {
  occurrenceList: OccurrenceListByCoverage[]
  title: string
  isVisible: boolean
  onClose: () => void
  onContinue: (data: FormProps) => void
}

const ChangeTactical = ({
  title,
  isVisible,
  onClose,
  onContinue,
  occurrenceList,
}: ChangeTacticalProps) => {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isValid },
    register,
  } = useForm({
    mode: 'all',
    resolver: joiResolver(schema),
  })

  const [stateRender, setStateRender] = useState<ChangeTacticalState>('FORM')

  const { data: agentDuties } = useGetAgentDetails(undefined, true)

  const onSubmit = (formData: FormProps) => {
    const { tactical } = formData
    const selectedTactical = agentDuties?.find(
      (duty) => duty.agentId === tactical.id,
    )
    const occurrenceId = selectedTactical?.occurrenceId
    const isOccurrenceInList = occurrenceList.some(
      (occurrence) => occurrence.id === occurrenceId,
    )

    const stateDecisions: Record<ChangeTacticalStateDecisions, () => boolean> =
      {
        ERROR: () => !!occurrenceId && !isOccurrenceInList,
        QUESTION: () => !!occurrenceId && isOccurrenceInList,
        CONTINUE: () => !occurrenceId,
      }

    const newState =
      (Object.keys(stateDecisions) as ChangeTacticalStateDecisions[]).find(
        (key) => stateDecisions[key](),
      ) || 'FORM'

    if (stateRender === 'QUESTION') {
      onContinue(formData)
      return
    }

    if (newState === 'CONTINUE') {
      onContinue(formData)
    } else {
      setStateRender(newState)
    }
  }

  const handleSelectTactical = (selected: AgentDuty) => {
    setValue(
      'tactical',
      {
        id: selected.agentId,
        name: selected.agentName,
        vehicleId: selected.vehicle.id,
        licensePlate: selected.vehicle.licensePlate,
        coordinates: selected.position,
      },
      {
        shouldValidate: true,
      },
    )
  }

  useEffect(() => {
    register('tactical')
  }, [register])

  const handleFilterTactical = (searchFilter: string, offset?: number) => {
    return fetchAgents().then((response) =>
      response.data.filter((data) =>
        !offset
          ? data.agentName.toLowerCase().includes(searchFilter.toLowerCase())
          : 0,
      ),
    )
  }

  return (
    <Modal
      title={title}
      isVisible={isVisible}
      className={styles.container}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          {
            FORM: (
              <>
                <FormItem>
                  <FormLabel>Tático</FormLabel>
                  <ComboBoxV3.Root
                    {...register('tactical')}
                    findOptions={handleFilterTactical}
                    valueKey="agentName"
                  >
                    {({ unSelectedOptions }) => (
                      <>
                        <ComboBoxV3.Field value={watch('tactical')?.name} />
                        <ComboBoxV3.Group>
                          {unSelectedOptions?.map((option) => (
                            <ComboBoxV3.Option
                              hasLabel
                              key={option.agentId}
                              aria-disabled={
                                option.status === 'PAUSED' ||
                                (!option.allowMultipleLinks &&
                                  option.occurrencesLinked?.length === 1)
                              }
                              onClick={() => {
                                handleSelectTactical(option)
                              }}
                            >
                              {option.agentName}
                              <span>
                                {option.status === 'PAUSED'
                                  ? 'Pausado'
                                  : option.occurrencesLinked?.length
                                    ? 'Ocupado'
                                    : 'Livre'}
                              </span>
                            </ComboBoxV3.Option>
                          ))}
                        </ComboBoxV3.Group>
                      </>
                    )}
                  </ComboBoxV3.Root>
                </FormItem>

                <div>
                  <Combobox
                    label={{ text: 'Observações' }}
                    items={[]}
                    onChange={(selected) =>
                      setValue('observation', selected, {
                        shouldValidate: true,
                      })
                    }
                  />
                  <textarea
                    {...register('comment')}
                    onChange={(event) =>
                      setValue('comment', event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                </div>
                <Modal.Footer>
                  <Button
                    type="secondary"
                    buttonTitle="Cancelar"
                    onClick={onClose}
                    width="172px"
                  />
                  <Button
                    type="primary"
                    buttonTitle="Continuar"
                    htmlType="submit"
                    disabled={!isValid}
                    width="172px"
                  />
                </Modal.Footer>
              </>
            ),
            ERROR: (
              <div>
                <p>
                  Este agente está respondendo a uma ocorrência que não pertence
                  ao seu perfil de atendimento. Não será possível designá-lo
                  para uma nova ocorrência até que ele esteja disponível.
                </p>
                <Modal.Footer>
                  <Button
                    type="primary"
                    onClick={() => setStateRender('FORM')}
                    buttonTitle="Voltar"
                    width="172px"
                  />
                </Modal.Footer>
              </div>
            ),
            QUESTION: (
              <div>
                <p>
                  Este tático já está vinculado a outra ocorrência. Tem certeza
                  de que deseja interromper o atendimento em andamento e
                  realocá-lo?
                </p>
                <Modal.Footer>
                  <Button
                    type="secondary"
                    buttonTitle="Cancelar"
                    onClick={() => setStateRender('FORM')}
                    width="172px"
                  />
                  <Button
                    type="primary"
                    buttonTitle="Continuar"
                    htmlType="submit"
                    disabled={!isValid}
                    width="172px"
                  />
                </Modal.Footer>
              </div>
            ),
          }[stateRender]
        }
      </form>
    </Modal>
  )
}

export { ChangeTactical }
