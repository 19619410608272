import AccountDriver from 'services/account'
import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import {
  FetchNextPageOptions,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import { useMemo } from 'react'
import { AccountQuery, AggregatedAccount } from 'services/account/types'

type UseGetAccounts = {
  accounts: AggregatedAccount[] | undefined
  isFetching: boolean
  isError: boolean
  isLoading: boolean
  refetch: () => void
  fetchNextAccountsPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getAccounts = async (
  offset = 0,
  recordsPerPage = 15,
  customerId?: string,
  filter?: { name?: string; patrimonyId?: string; code?: string },
  patrimonyId?: string,
) => {
  const response = await AccountDriver.queryAggregatedAccounts({
    recordsPerPage,
    offset,
    ...(customerId && { customerId }),
    ...(patrimonyId && { patrimonyId }),
    ...(filter && filter),
  })

  return response
}

export const useFilterAccounts = (query: AccountQuery) => {
  return useQuery({
    queryKey: ['filter-account', query.offset],
    queryFn: async () => {
      const response = await AccountDriver.queryAggregatedAccounts(query)

      return response
    },
  })
}

export function useGetAccounts(
  enabled: boolean,
  customerId?: string,
  filter?: { name?: string; patrimonyId?: string; code?: string },
  recordsPerPage?: number,
  patrimonyId?: string,
): UseGetAccounts {
  const {
    isError,
    data,
    isFetching,
    isLoading,
    refetch,
    fetchNextPage: fetchNextAccountsPage,
  } = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['accounts', filter, customerId, patrimonyId],
    queryFn: ({ pageParam }) =>
      getAccounts(pageParam, recordsPerPage, customerId, filter, patrimonyId),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<AggregatedAccount>(lastPage, allPages),
    refetchOnWindowFocus: false,
    enabled,
  })

  const accounts = useMemo(() => flattenPages<AggregatedAccount>(data), [data])

  return {
    isError,
    refetch,
    accounts,
    fetchNextAccountsPage,
    isFetching,
    isLoading,
  }
}
