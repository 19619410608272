import { FC, SVGProps, Suspense, lazy, useMemo } from 'react'

import styles from './styles.module.scss'
import { IconName } from './icon-names'
import joinClassNames from 'utilities/joinClassNames'

export const colors = {
  neutral: 'neutral',
  gray: 'gray',
  'medium-gray': 'medium-gray',
  'bold-gray': 'bold-gray',
  danger: 'danger',
  brand: 'brand',
  element: 'element',
}

export type Colors = keyof typeof colors
type SVGElement = SVGProps<SVGSVGElement>

export interface IconProps extends Omit<SVGElement, 'fill' | 'ref'> {
  name: IconName
  color?: Colors
}

const Icon = ({
  name,
  className,
  width = 16,
  height = 16,
  color = 'brand',
  ...props
}: IconProps) => {
  const IconComponent = useMemo(
    () =>
      lazy(async () => ({
        default: (await import(`./svg/${name}.svg`))
          .ReactComponent as FC<SVGElement>,
      })),
    [name],
  )

  return (
    <Suspense>
      <IconComponent
        {...props}
        width={width}
        height={height}
        className={joinClassNames(styles[color], className)}
      />
    </Suspense>
  )
}

export default Icon
