import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { CoverageType } from 'services/occurrence/types'
import { UserQueryResult } from 'services/auth'
import buildUrl from 'services/utils/buildUrl'
import HttpClient from 'services/httpClient'
import { endpoints } from './endpoints'
import { useMemo } from 'react'
import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { SERVICE_ORDER_TYPE_ENUM } from '../types'

export type QueryTechniciansResult = UserQueryResult & {
  isAccountTechnician: boolean
}

type UseGetTechnicians = {
  technicians: QueryTechniciansResult[] | undefined
  isFetching: boolean
  isError: boolean
  isLoading: boolean
  fetchNextTechniciansPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const fetchTechnicians = async (
  accountId: string,
  serviceOrderType: SERVICE_ORDER_TYPE_ENUM,
  offset = 0,
  filter?: string,
) => {
  const response = await HttpClient.get(
    buildUrl({ route: endpoints.queryTechnicians, params: { accountId } }),
    {
      params: {
        serviceOrderTypes: serviceOrderType,
        coverageTypes: [CoverageType.TECHNICAL_AGENT],
        recordsPerPage: 15,
        offset,
        ...(filter && {
          name: filter,
        }),
      },
    },
  )
  return response.data
}

export const useInfinityTechnicians = (
  accountId: string,
  serviceOrderType: SERVICE_ORDER_TYPE_ENUM,
  name?: string,
): UseGetTechnicians => {
  const {
    isError,
    data,
    isFetching,
    isLoading,
    fetchNextPage: fetchNextTechniciansPage,
  } = useInfiniteQuery({
    queryKey: ['technicians', accountId, serviceOrderType, name],
    queryFn: ({ pageParam }) =>
      fetchTechnicians(accountId, serviceOrderType, pageParam, name),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<QueryTechniciansResult>(lastPage, allPages),
    refetchOnWindowFocus: false,
    enabled: !!accountId && !!serviceOrderType,
  })

  const technicians = useMemo(() => {
    return flattenPages<QueryTechniciansResult>(data)
  }, [data])

  return {
    isError,
    technicians,
    fetchNextTechniciansPage,
    isFetching,
    isLoading,
  }
}
