import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Loader,
  Input,
  Button,
  ServiceOrderFilters,
  Popover,
  EmptyState,
  LoaderV2,
  TableV2 as Table,
  Droplist,
  Icon,
  PaginationV2 as Pagination,
} from 'components'
import {
  ServiceOrderAggregatedQueryResponse,
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_TYPE_ENUM,
  SERVICE_ORDER_TYPE_EN_PT,
  useGetServiceOrderHistory,
  ServiceOrderDriver,
} from 'services/serviceOrder'

import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'

import styles from './UnscheduledServiceOrders.module.scss'
import { maskedDateTime } from 'utilities/date'
import {
  useOSFilter,
  handleStatusLabel,
  isServiceOrderCancelable,
} from 'domains/serviceOrders/utilities'
import { ScheduleServiceOrderModal } from '../../../components/ScheduleServiceOrderModal'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { useToggle, useUserInfo } from 'shared/hooks'

import { usePutAndHandleServiceOrder } from 'domains/serviceOrders/hooks/services/usePutAndHandleServiceOrder'

import {
  ServiceOrderFiltersSchema,
  filterSchema,
} from 'components/ServiceOrderFilters/schemas/searchServiceOrderSchema'

import { CancelServiceOrder } from 'domains/serviceOrders/components/CancelServiceOrder/CancelServiceOrder'

import { RichTooltip } from '../../components'
import { UbideskPermissions } from 'routes/types'
import { usePersistentTab } from 'domains/occurrence/hooks'
import { ServiceOrderPayload } from 'domains/serviceOrders/types'
import { useSearchParams } from 'react-router-dom'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const columnHelper = createColumnHelper<ServiceOrderAggregatedQueryResponse>()

export const UnscheduledServiceOrdersScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filterComponent = useToggle()
  const { userInfo } = useUserInfo()
  const { filters, handleResetFilter } = useOSFilter()
  const [serviceOrderId, setServiceOrderId] = useState('')

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '1',
    })
  }

  const [scheduleModalOpen, setScheduledModalOpen] = useState(false)
  const [serviceOrderSchedule, setServiceOrderSchedule] =
    useState<ServiceOrderPayload>()

  const { replaceUrl } = usePersistentTab('view-service-order')

  const [selectedServiceOrder, setSelectedServiceOrder] =
    useState<ServiceOrderAggregatedQueryResponse>()

  const cancelServiceOrderComponent = useToggle()

  const form = useForm<ServiceOrderFiltersSchema>({
    resolver: joiResolver(filterSchema),
    defaultValues: { applyAttendanceExclusivity: true, ...filters },
  })
  const { reset, watch, register } = form

  const { mutatePutServiceOrder, updateServiceOrderStatus } =
    usePutAndHandleServiceOrder({
      messages: {
        success: 'Ordem de serviço agendada com sucesso.',
        error: 'Não foi possível agendar a ordem de serviço. Tente novamente.',
      },
    })

  const handleScheduleServiceOrder = (data: ServiceOrderPayload) => {
    const {
      id,
      status,
      note,
      technician,
      contact,
      scheduleDate,
      schedulePeriod,
      tags,
      account,
      type,
    } = data

    mutatePutServiceOrder(
      {
        id,
        status,
        note,
        technicianId: technician?.id,
        userId: userInfo.id,
        accountId: account?.id || '',
        serviceOrderType: type,
        contact,
        scheduleDate,
        ...(schedulePeriod && { schedulePeriod }),
        ...(tags?.length
          ? {
              tags: tags.map((tag) => ({ name: tag.name })),
            }
          : []),
      },
      {
        onSuccess: () => {
          setScheduledModalOpen(false)
          fetchServiceOrders()
        },
      },
    )
  }

  const { number } = watch()

  const { data: serviceOrderHistory } =
    useGetServiceOrderHistory(serviceOrderId)

  const hasInProgress = serviceOrderHistory?.some(
    (historyEntry) =>
      historyEntry.status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
  )

  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState({ data: [], totalElements: 0 })

  const fetchServiceOrders = useCallback(async () => {
    const {
      number,
      customer,
      patrimony,
      account,
      type,
      technicians,
      status,
      createdAtFrom,
      createdAtTo,
      scheduleDateFrom,
      scheduleDateTo,
      closedAtFrom,
      closedAtTo,
      tag,
      origin,
      reason,
      pauseReason,
      issue,
      resolution,
      applyAttendanceExclusivity,
    } = watch()

    if (number) {
      reset({
        number,
        applyAttendanceExclusivity,
      })
    }

    const labels = [origin, reason, pauseReason, issue, resolution].reduce(
      (acc, array) => [...acc, ...(array || [])],
      [],
    )

    setIsFetching(true)

    const page = Number(searchParams.get('page')) || 1
    const offset = (page - 1) * 15

    try {
      const serviceOrders = await ServiceOrderDriver.queryPost({
        offset,
        recordsPerPage: 15,
        status: [SERVICE_ORDER_STATUS_ENUM.OPEN],
        applyAttendanceExclusivity,
        coverageTypes: ['SERVICE_ORDER_ATTENDANT'],
        ...(userInfo && { userId: userInfo.id }),
        ...(number && { number }),
        ...(account && { accountIds: [account.id] }),
        ...(technicians && {
          technicianIds: technicians.map((technician) => technician.id),
        }),
        ...(createdAtFrom && { createdAtFrom }),
        ...(createdAtTo && { createdAtTo }),
        ...(scheduleDateFrom && { scheduleDateFrom }),
        ...(scheduleDateTo && { scheduleDateTo }),
        ...(closedAtFrom && { closedAtFrom }),
        ...(closedAtTo && { closedAtTo }),
        ...(labels?.length && { labelIds: labels.map((label) => label.id) }),
        ...(tag && { tagId: tag.id }),
        ...(type && { serviceOrderTypes: type }),
        ...(patrimony && !account ? { accountIds: [patrimony.accountId] } : {}),
        ...(customer && !account && !patrimony
          ? {
              accountIds: customer.accounts.length
                ? customer.accounts.map((account) => account.id)
                : [customer.id],
            }
          : {}),
        ...(!!status?.length && { status }),
        hasTechnician: watch('hasTechnician'),
        ...(!!watch('serviceType') && {
          serviceTypeId: watch('serviceType').id,
        }),
        ...(!!watch('accountTag') && {
          accountTagId: watch('accountTag').id,
        }),
      })

      setData(serviceOrders)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [userInfo, reset, watch, searchParams])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchServiceOrders()
    }
  }, [page, filter])

  const actions = useMemo(
    () => [
      {
        label: 'Visualizar',
        Icon: 'view',
        handler: (serviceOrder) => {
          setServiceOrderId(serviceOrder.id)
          replaceUrl(`/so/info/${serviceOrder.id}`)
        },
      },
      {
        label: 'Agendar',
        Icon: 'calendar',
        handler: (serviceOrder: ServiceOrderAggregatedQueryResponse) => {
          setServiceOrderSchedule(serviceOrder)
          setScheduledModalOpen(true)
        },
      },
      {
        label: 'Cancelar',
        Icon: 'close-lg',
        handler: (item) => {
          setSelectedServiceOrder(item)
          cancelServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderCancelable(item.status),
        permission: {
          name: hasInProgress
            ? UbideskPermissions.CANCEL_EXECUTED_SO
            : undefined,
          message: 'Não é possível cancelar esta OS, pois já possui execuções.',
        },
      },
    ],
    [hasInProgress],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'id',
        header: 'Número',
        maxSize: 115,
        cell: (info) => (
          <div className={styles.statusBox}>
            <span>{info.getValue().number}</span>
            <Popover.Root>
              <Popover.Content>
                <RichTooltip serviceOrderId={info.getValue().id} />
              </Popover.Content>
              <Popover.Trigger className={styles[info.getValue().status]}>
                {handleStatusLabel(info.getValue().status)}
                <InfoIcon />
              </Popover.Trigger>
            </Popover.Root>
          </div>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: 'account',
        header: 'Conta',
        cell: (info) =>
          `${info.getValue().account?.code} - ${info.getValue().customer?.name}`,
      }),
      columnHelper.accessor('labels', {
        header: 'Motivo',
        cell: (info) => {
          const value = info.getValue()

          const reasonLabel = value?.find((label) => label.type === 'REASON')

          return reasonLabel?.description || ''
        },
      }),
      columnHelper.accessor('patrimony', {
        header: 'Patrimônio',
        cell: (info) => info.getValue()?.name,
      }),
      columnHelper.accessor('type', {
        header: 'Tipo',
        cell: (info) => (
          <>
            {
              SERVICE_ORDER_TYPE_EN_PT[
                info.getValue() as SERVICE_ORDER_TYPE_ENUM
              ]
            }
          </>
        ),
      }),
      columnHelper.accessor('technician', {
        header: 'Técnico',
        cell: (info) => info.getValue()?.name,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Abertura',
        size: 96,
        cell: (info) => <>{maskedDateTime(info.getValue())}</>,
      }),
      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 46,
        cell: (info) => {
          const showDropList = actions.some((action) =>
            action.isVisible?.(info.getValue()),
          )

          const value = info.getValue()
          return showDropList ? (
            <Droplist
              trigger={<Icon name="menu-kebab" style={{ cursor: 'pointer' }} />}
            >
              <ul className={styles.dropListItem}>
                {actions.map((action) => {
                  const shouldShow = action.isVisible?.(value) ?? true

                  return (
                    shouldShow && (
                      <li
                        key={action.label}
                        onClick={() => action.handler(value)}
                      >
                        <Icon
                          name={action.Icon}
                          style={{ cursor: 'pointer' }}
                        />
                        {action.label}
                      </li>
                    )
                  )
                })}
              </ul>
            </Droplist>
          ) : (
            <Icon
              name={actions[0].Icon}
              onClick={() => actions[0].handler(value)}
              style={{ cursor: 'pointer' }}
            />
          )
        },
      }),
    ],
    [columnHelper, actions],
  )

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  return (
    <>
      <Loader isVisible={updateServiceOrderStatus === 'pending'} />
      <CancelServiceOrder
        isVisible={cancelServiceOrderComponent.isVisible}
        onClose={() => {
          setSelectedServiceOrder(undefined)
          cancelServiceOrderComponent.hide()
        }}
        serviceOrder={{
          id: selectedServiceOrder?.id || '',
          number: selectedServiceOrder?.number || 0,
          technicianId: selectedServiceOrder?.technician?.id,
        }}
      />
      <div className={styles.container}>
        <span className={styles.title}>Ordens de serviço</span>
        <div className={styles.divider} />
        <FormProvider {...form}>
          <form
            className={styles.inputsWrapper}
            onSubmit={(event) => {
              event.preventDefault()
              handleResetPagination()

              handleResetFilter()

              reset({
                number: watch('number'),
                applyAttendanceExclusivity: watch('applyAttendanceExclusivity'),
              })

              fetchServiceOrders()
            }}
          >
            <div className={styles.innerWrapper}>
              <div className={styles.filtersButtons}>
                <Input
                  id="number"
                  placeholder="Digite o número identificador da OS"
                  label="Número da OS"
                  autoComplete="off"
                  type="number"
                  {...register('number')}
                  disabled={filterComponent.isVisible}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  disabled={filterComponent.isVisible}
                  htmlType="submit"
                />
                <Button
                  buttonTitle={'Adicionar filtros'}
                  type="tertiary"
                  icon={FiltersIcon}
                  onClick={filterComponent.show}
                />
              </div>
            </div>
          </form>

          {
            {
              view: (
                <Table.Root>
                  <Table.Header>
                    {table?.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Table.ResizableColumn
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.column.getSize() }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Table.ResizableColumn>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Header>
                  <Table.Body>
                    {table?.getRowModel().rows.map((row) => (
                      <Fragment key={row.id}>
                        <Table.Row>
                          {row.getVisibleCells().map((cell) => (
                            <Table.Cell
                              key={cell.id}
                              className={styles[cell.column.id]}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      </Fragment>
                    ))}
                  </Table.Body>
                </Table.Root>
              ),
              empty: <EmptyState type="EmptyDataFromBFF" />,
              error: <EmptyState type="ErrorToLoadInfos" />,
              loading: (
                <div className={styles.loader}>
                  <LoaderV2 />
                </div>
              ),
            }[handleRender(data?.data, isError, isFetching)]
          }
          <Pagination
            className={styles.pagination}
            totalElements={data?.totalElements}
          />
          <ServiceOrderFilters
            hiddenFilters={['status', 'schedule', 'finishedAt']}
            isVisible={filterComponent.isVisible}
            onClose={filterComponent.hide}
            onApplyFilters={() => {
              if (number) {
                form.setValue('number', undefined)
              }

              filterComponent.hide()
              handleResetPagination()
            }}
          />
        </FormProvider>

        {scheduleModalOpen && (
          <ScheduleServiceOrderModal
            isVisible={scheduleModalOpen}
            onClose={() => {
              form.reset()
              setScheduledModalOpen(false)
            }}
            serviceOrder={serviceOrderSchedule}
            onSave={(data) => {
              handleScheduleServiceOrder(data)
              setScheduledModalOpen(false)
            }}
          />
        )}
      </div>
    </>
  )
}
