import Joi, { CustomHelpers } from '@hapi/joi'

import {
  OfficeHoursFormData,
  ScaleWithDayWork,
} from 'domains/customer/screens/OfficeHours/types'
import { dayOfWorkOptions } from 'domains/customer/utilities/dayOfWork'
import {
  AccountCoverage,
  Coverage,
  PartitionCoverage,
  ScalePeriod,
} from 'services/officeHours/types'
import { PartitionStatus } from 'services/patrimony/types'

const periodSchema = Joi.object<ScalePeriod>({
  partitionStatus: Joi.string()
    .valid(...Object.values(PartitionStatus))
    .required(),
  startTime: Joi.string()
    .regex(/^([01]\d|2[0-3]):([03]0)$/)
    .required()
    .messages({
      'string.pattern.base':
        'The startTime field must be in the format HH:00 or HH:30.',
    }),
  endTime: Joi.string()
    .regex(/^([01]\d|2[0-3]):([25]9)$/)
    .messages({
      'string.pattern.base':
        'The endTime field must be in the format HH:29 or HH:59.',
    })
    .required(),
})

const scaleSchema = Joi.object<ScaleWithDayWork>({
  dayOfWork: Joi.string()
    .valid(...Object.values(dayOfWorkOptions))
    .required(),
  periods: Joi.array().items(periodSchema).required(),
})

const coverageFragment = Joi.object<Coverage>({
  id: Joi.string().uuid().required(),
  name: Joi.string().required(),
})

const accountCoverageSchema = Joi.object<AccountCoverage>({
  id: Joi.string().uuid().required(),
  accountCode: Joi.string().required(),
  precedents: Joi.object({
    patrimony: coverageFragment.optional(),
    central: coverageFragment.optional(),
  }).optional(),
})

const partitionCoverageSchema = Joi.object<PartitionCoverage>({
  id: Joi.string().uuid().required(),
  name: Joi.string().required(),
  precedents: Joi.object({
    patrimony: coverageFragment.optional(),
    central: coverageFragment.optional(),
    account: coverageFragment.optional(),
  }).optional(),
})

const validateCoverage = (
  value: OfficeHoursFormData['coverage'],
  helpers: CustomHelpers,
) => {
  const atLeastOneCoverageElement =
    value.patrimonies.length > 0 ||
    value.accounts.length > 0 ||
    value.partitions.length > 0

  if (!atLeastOneCoverageElement) {
    return helpers.message({
      'number.any':
        'At least one of patrimonies, accounts, or partitions must have at least one element',
    })
  }

  return value
}

const coverageSchema = Joi.object<OfficeHoursFormData['coverage']>({
  patrimonies: Joi.array().items(coverageFragment),
  accounts: Joi.array().items(accountCoverageSchema),
  partitions: Joi.array().items(partitionCoverageSchema),
})

const parameters = Joi.object<OfficeHoursFormData['parameters']>({
  active: Joi.boolean().optional(),
  armedOutOfScaleActive: Joi.boolean().optional(),
  disarmedOutOfScaleActive: Joi.boolean().optional(),
  notArmedActive: Joi.boolean().optional(),
  notDisarmedActive: Joi.boolean().optional(),
})

export const officeHoursSchema = Joi.object<OfficeHoursFormData>({
  name: Joi.string().required(),
  coverage: coverageSchema.custom(validateCoverage).required(),
  tolerance: Joi.number().required(),
  scales: Joi.array().items(scaleSchema).min(1).required(),
  parameters: parameters.optional(),
})
