export enum UbideskPermissions {
  UBIDESK = 'ubidesk',
  ACCOUNT = 'ubidesk.account',
  SERVICE_ORDER = 'ubidesk.so',
  SO_TECHNICIAN_SCHEDULE_PAUSE = 'ubidesk.so.technicianSchedulePause',
  CANCEL_EXECUTED_SO = 'ubidesk.so.cancelExecutedServiceOrder',
  USER = 'ubidesk.user',
  CONFIG = 'ubidesk.config',
  EQUIPMENTS_WRITE = 'ubidesk.account.equipments.write',
  CONFIG_ATTENDANCE_PROFILE = 'ubidesk.config.attendanceProfile',
  CONFIG_ATTENDANCE_POLICY = 'ubidesk.config.attendancePolicy',
  CONFIG_VEHICLE = 'ubidesk.config.vehicle',
  CONFIG_HOLIDAY = 'ubidesk.config.holiday',
  CONFIG_USER = 'ubidesk.config.user',
  CONFIG_USER_WRITE = 'ubidesk.config.user.write',
}

export type HomeLocationState = {
  hasToLogonStation: boolean
}
