import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ReactComponent as CamIcon } from 'assets/svg/cameraIcon.svg'
import { ReactComponent as PlusSign } from 'assets/svg/plusSign.svg'
import styles from './CentralForm.module.scss'

import { DragAndDrop } from 'domains/attendancePolicy/components/AutomationList/components/DragAndDropAutomation/DragAndDropAutomation'
import { getPartitionStatusLabel } from 'domains/customer/utilities/partitions/getPartitionStatusLabel'
import { createCentralSchema } from 'domains/customer/schemas/central/centralSchema'
import { Button, Input, ProtectedImage } from 'components'

import { useGetDeviceType } from 'shared/hooks/services/device/useGetDeviceType'
import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'
import { useInfiniteManufacturers } from 'services/manufacturer'
import { ManufacturerOutput } from 'services/manufacturer/types'
import { DeviceModelOutput } from 'services/deviceModel/types'
import { useInfiniteDeviceModel } from 'services/deviceModel'
import { AggregatedAccount } from 'services/account/types'
import { PartitionStatus } from 'services/patrimony/types'
import { joiResolver } from '@hookform/resolvers/joi'
import { useDebounce, useToggle } from 'shared/hooks'

import {
  CentralFindByIdResult,
  CreateCentralPayload,
  PartitionFragmentPayload,
} from 'services/central/types'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { AddPartitionModal } from '../AddPartitionModal/AddPartitionModal'

import { formatMACORIMEI } from 'utilities/masks/macAndImei/macAndImei'
import { validateHexdecimalCharacter } from 'utilities/validation'
import { parseDataToComboboxV2 } from 'utilities/combobox'

type CentralFormProps = {
  accountId?: string
  centralToUpdate?: CentralFindByIdResult
  onSubmit: (data: CreateCentralPayload) => void
}

interface Filter {
  account?: string
  deviceModel?: string
  manufacturer?: string
}

export const CentralForm: React.FC<CentralFormProps> = ({
  accountId,
  centralToUpdate,
  onSubmit,
}) => {
  const CENTRAL_DEVICE_TYPE = '6'

  const partitionModal = useToggle()
  const navigate = useNavigate()

  const storageAccountId = localStorage.getItem('accountId') || ''
  const storageAggregatedAccountName =
    localStorage.getItem('aggregatedAccountName') || ''

  const form = useForm<CreateCentralPayload>({
    mode: 'onChange',
    resolver: joiResolver(createCentralSchema),
    defaultValues: {
      central: {
        code: formatMACORIMEI(centralToUpdate?.central.code || ''),
        model: centralToUpdate?.central.model,
        manufacturer: centralToUpdate?.central.manufacturer,
        topicGroup: centralToUpdate?.central?.topicGroup,
        externalId: centralToUpdate?.central?.externalId,
      },
      contacts:
        centralToUpdate?.contacts.map(({ id, name, code }) => ({
          id,
          name,
          code,
        })) || [],
      account: {
        id: centralToUpdate?.central.account.id ?? storageAccountId,
        aggregatedAccountName:
          centralToUpdate?.central.account.aggregatedAccountName ??
          storageAggregatedAccountName,
      },
      partitions: centralToUpdate?.partitions || [],
    },
  })

  const { watch, setValue, register, handleSubmit, formState } = form

  const [selectedImage, setSelectedImage] = useState<File | undefined>()

  const [filter, setFilter] = useState<Filter>({
    deviceModel: '',
    account: '',
    manufacturer: '',
  })

  const handleFilter = useDebounce(setFilter)

  const [selectedPartitionId, setSelectedPartitionId] = useState()

  const customerId = localStorage.getItem('customerId') || ''

  const partitions = watch('partitions')
  const account = watch('account')
  const central = watch('central')

  const selectedPartition = useMemo(
    () => partitions?.find((partition) => partition.id === selectedPartitionId),
    [partitions, selectedPartitionId],
  )

  const { deviceType } = useGetDeviceType(CENTRAL_DEVICE_TYPE, true)

  const {
    accounts,
    isError: isAccountsError,
    isFetching: isAccountsFetching,
    fetchNextAccountsPage,
  } = useGetAccounts(!accountId, customerId, {
    code: filter.account,
  })

  const {
    data: manufacturers,
    isError: isManufacturersError,
    isFetching: isManufacturersFetching,
    fetchNextPage: fetchNextManufacturersPage,
  } = useInfiniteManufacturers({
    name: filter.manufacturer,
  })

  const {
    data: deviceModels,
    isError: isDeviceModelsError,
    isLoading: isDeviceModelsFetching,
    fetchNextPage: fetchNextDeviceModelsPage,
  } = useInfiniteDeviceModel({
    filter: {
      name: filter.deviceModel,
    },
    query: {
      hasCommandSupport: false,
      deviceTypeId: deviceType?.[0].id,
      manufacturerId: watch('central')?.manufacturer?.id,
    },
  })

  const handleAddPartition = useCallback(
    (partitionPayload: PartitionFragmentPayload) => {
      setValue(
        'partitions',

        partitionPayload?.id
          ? [
              ...partitions.filter(
                (partition) => partition.id !== partitionPayload.id,
              ),
              partitionPayload,
            ]
          : [...partitions, partitionPayload],

        {
          shouldValidate: true,
          shouldDirty: true,
        },
      )

      partitionModal.hide()
    },
    [partitionModal, partitions, setValue],
  )

  const getItem = (
    item: PartitionFragmentPayload,
  ): { id: string; code: string; title: string; subtitle: string } => ({
    id: item.id,
    code: item.code,
    title: `${item.code} - ${item.name}`,
    subtitle: getPartitionStatusLabel(item.status || PartitionStatus.DISARMED),
  })

  useEffect(() => {
    register('contacts')
  }, [register])

  return (
    <>
      {partitionModal.isVisible && (
        <AddPartitionModal
          onSave={handleAddPartition}
          onClose={partitionModal.hide}
          partition={selectedPartition}
          hasCommandSupport={watch('central')?.model.hasCommandSupport}
        />
      )}

      <main className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <section className={styles.centralForm}>
            <div className={styles.formWrapper}>
              <Combobox
                id="account"
                label={{
                  text: 'Conta',
                }}
                {...register('account')}
                itemLabel={(account) => {
                  const accountItem = account as ComboboxItem<AggregatedAccount>
                  return accountItem.value.central?.id ? 'Em uso' : ''
                }}
                isDisabled={(account) => {
                  const accountItem = account as ComboboxItem<AggregatedAccount>
                  return !!accountItem.value.central?.id
                }}
                items={parseDataToComboboxV2(
                  accounts || [],
                  'aggregatedAccountName',
                )}
                value={
                  account
                    ? {
                        label: 'aggregatedAccountName',
                        value: account,
                      }
                    : localStorage.getItem('aggregatedAccountName') || ''
                }
                onSearch={(text) => handleFilter({ account: text })}
                onChange={(selected) => {
                  const account = selected as ComboboxItem<
                    Pick<AggregatedAccount, 'id' | 'aggregatedAccountName'>
                  >

                  if (account.value)
                    setValue(
                      'account',
                      {
                        id: account.value.id,
                        aggregatedAccountName:
                          account.value.aggregatedAccountName,
                      },
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                      },
                    )
                }}
                disabled={
                  Boolean(accountId) ||
                  centralToUpdate?.central.model.hasCommandSupport
                }
                isError={isAccountsError}
                isLoading={isAccountsFetching}
                onEndReached={fetchNextAccountsPage}
              />

              <Combobox
                id="manufacturer"
                label={{
                  text: 'Fabricante da central',
                }}
                {...register('central')}
                items={parseDataToComboboxV2(
                  manufacturers?.map((manufacturer) => ({
                    id: manufacturer.id,
                    name: manufacturer.name,
                  })) || [],
                  'name',
                )}
                value={
                  central?.manufacturer
                    ? {
                        label: 'name',
                        value: central?.manufacturer,
                      }
                    : undefined
                }
                onSearch={(text) => {
                  if (text === '') {
                    setValue(
                      'central',
                      {
                        ...central,
                        manufacturer: undefined,
                        model: undefined,
                      },
                      { shouldValidate: true, shouldDirty: true },
                    )
                  }
                  handleFilter({ manufacturer: text })
                }}
                onChange={(selected) => {
                  const manufacturer = selected as ComboboxItem<
                    Pick<ManufacturerOutput, 'id' | 'name'>
                  >

                  if (manufacturer.value) {
                    setValue(
                      'central',
                      {
                        ...central,
                        manufacturer: manufacturer.value,
                        model: undefined,
                      },
                      { shouldValidate: true, shouldDirty: true },
                    )
                  }
                }}
                disabled={centralToUpdate?.central.model.hasCommandSupport}
                isError={isManufacturersError}
                isLoading={isManufacturersFetching}
                onEndReached={fetchNextManufacturersPage}
              />

              <Combobox
                id="deviceModel"
                label={{
                  text: 'Modelo da central',
                }}
                items={parseDataToComboboxV2(
                  deviceModels?.map((deviceModel) => ({
                    id: deviceModel.id,
                    name: deviceModel.name,
                    hasCommandSupport: deviceModel.properties.hasCommandSupport,
                  })) || [],
                  'name',
                )}
                value={
                  central?.model
                    ? {
                        label: 'name',
                        value: central?.model,
                      }
                    : undefined
                }
                onSearch={(text) => handleFilter({ deviceModel: text })}
                onChange={(selected) => {
                  const model = selected as ComboboxItem<
                    Pick<DeviceModelOutput, 'id' | 'name'> & {
                      hasCommandSupport: boolean
                    }
                  >
                  if (model.value)
                    setValue(
                      'central',
                      {
                        ...central,
                        model: {
                          ...model.value,
                          hasCommandSupport: model.value.hasCommandSupport,
                        },
                      },
                      { shouldValidate: true, shouldDirty: true },
                    )
                }}
                disabled={
                  !watch('central').manufacturer ||
                  centralToUpdate?.central.model.hasCommandSupport
                }
                isError={isDeviceModelsError}
                isLoading={isDeviceModelsFetching}
                onEndReached={fetchNextDeviceModelsPage}
              />
              <Input
                id="central-code"
                label="Código da central"
                type="text"
                value={central?.code || ''}
                disabled={centralToUpdate?.central.model.hasCommandSupport}
                onChange={(e) => {
                  setValue(
                    'central',
                    {
                      ...central,
                      code: formatMACORIMEI(e.target.value),
                    },
                    { shouldValidate: true, shouldDirty: true },
                  )
                }}
              />

              {!centralToUpdate?.central.model.hasCommandSupport && (
                <>
                  <Input
                    id="central-group"
                    label="Grupo de centrais"
                    type="text"
                    maxLength={4}
                    value={central?.topicGroup || ''}
                    onChange={(e) => {
                      const input = e.target.value || ''
                      if (validateHexdecimalCharacter(input) || input === '') {
                        setValue(
                          'central',
                          {
                            ...central,
                            topicGroup: e.target.value.toUpperCase(),
                          },
                          { shouldValidate: true, shouldDirty: true },
                        )
                      }
                    }}
                  />

                  <Input
                    id="externalId"
                    label="Conta da central"
                    type="text"
                    maxLength={4}
                    value={central?.externalId || ''}
                    onChange={(e) => {
                      const input = e.target.value || ''
                      if (validateHexdecimalCharacter(input) || input === '') {
                        setValue(
                          'central',
                          {
                            ...central,
                            externalId: e.target.value.toUpperCase(),
                          },
                          { shouldValidate: true, shouldDirty: true },
                        )
                      }
                    }}
                  />
                </>
              )}
            </div>
          </section>

          <section className={styles.partitionForm}>
            <div>
              <h1 className={styles.partitionsTitle}>Partições</h1>

              <div className={styles.partitionsContainer}>
                <div className={styles.partitionsHeader}>
                  <h2 className={styles.partitionsHeaderTitle}>Partição</h2>
                  {!centralToUpdate?.central.model.hasCommandSupport && (
                    <Button
                      buttonTitle="Nova partição"
                      type="tertiary"
                      icon={PlusSign}
                      onClick={() => {
                        setSelectedPartitionId(undefined)
                        partitionModal.show()
                      }}
                    />
                  )}
                </div>

                {/* TODO: ajustar lógica de render das ações do drag n drop */}
                {partitions?.length && !centralToUpdate ? (
                  <DragAndDrop
                    items={partitions.sort((a, b) => {
                      const codeA = a.code.toLowerCase()
                      const codeB = b.code.toLowerCase()

                      return codeA < codeB ? -1 : codeA > codeB ? 1 : 0
                    })}
                    getItem={getItem}
                    {...register('partitions')}
                    onDelete={(partitionToDelete) =>
                      setValue(
                        'partitions',
                        partitions.filter(
                          (partition) =>
                            partition.code !== partitionToDelete.code,
                        ),
                        { shouldValidate: true, shouldDirty: true },
                      )
                    }
                    draggable={false}
                  />
                ) : null}
                {partitions?.length && centralToUpdate ? (
                  <DragAndDrop
                    items={partitions.sort((a, b) => {
                      const codeA = a.code.toLowerCase()
                      const codeB = b.code.toLowerCase()

                      return codeA < codeB ? -1 : codeA > codeB ? 1 : 0
                    })}
                    getItem={getItem}
                    onEdit={(item) => {
                      setSelectedPartitionId(item.id)
                      partitionModal.show()
                    }}
                    draggable={false}
                    canDelete={false}
                  />
                ) : null}
              </div>

              <div className={styles.inputImage}>
                <label>
                  {!selectedImage &&
                    !centralToUpdate?.central?.installationImageId && (
                      <div className={styles.uploadImageWrapper}>
                        <label
                          className={styles.uploadLink}
                          htmlFor="central-image"
                        >
                          <CamIcon width={16} height={14} /> Upload de foto de
                          instalação (opcional)
                        </label>
                      </div>
                    )}

                  {selectedImage ? (
                    <div>
                      <span className={styles.installationImageLabel}>
                        Foto de instalação
                      </span>
                      <div className={styles.imageWrapper}>
                        <img
                          className={styles.centralImage}
                          src={URL.createObjectURL(selectedImage)}
                          alt="Imagem selecionada da central"
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {!!centralToUpdate?.central.installationImageId && (
                        <div>
                          <span className={styles.installationImageLabel}>
                            Foto de instalação
                          </span>
                          <div className={styles.imageWrapper}>
                            <ProtectedImage
                              className={styles.centralImage}
                              imageId={
                                centralToUpdate.central.installationImageId
                              }
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <input
                    type="file"
                    id="central-image"
                    className={styles.uploadLinkInput}
                    aria-label="central-image-input"
                    accept="image/*"
                    {...register('image')}
                    onChange={(e) => {
                      setSelectedImage(e.currentTarget.files?.[0])

                      setValue('image', e.currentTarget.files?.[0], {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                  />
                </label>
              </div>
            </div>
          </section>

          <footer className={styles.footer}>
            <Button
              width="172px"
              buttonTitle="Voltar para Centrais"
              type="secondary"
              onClick={() => navigate('/account/management/central')}
            />
            <Button
              width="172px"
              buttonTitle="Salvar"
              type="primary"
              htmlType="submit"
              disabled={!formState.isValid}
            />
          </footer>
        </form>
      </main>
    </>
  )
}
