import {
  ComponentProps,
  ReactElement,
  cloneElement,
  createContext,
  isValidElement,
  useContext,
  useState,
  MouseEvent,
  useCallback,
  useEffect,
} from 'react'
import ReactDOM from 'react-dom'

import styles from './styles.module.scss'
import joinClassNames from 'utilities/joinClassNames'

interface ModalContextProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const ModalContext = createContext<ModalContextProps>({
  isOpen: false,
  onClose: () => null,
  onOpen: () => null,
})

const useModalContext = () => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider')
  }

  return context
}

interface RootProps extends ComponentProps<'div'> {
  isOpen?: boolean
  onClose?: () => void
}

const Root = ({ isOpen = false, onClose, ...props }: RootProps) => {
  const [open, setOpen] = useState(isOpen)

  const handleClose = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [onClose])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    if (open !== isOpen) {
      setOpen(isOpen)
    }
  }, [isOpen])

  return (
    <ModalContext.Provider
      value={{
        isOpen: open,
        onClose: handleClose,
        onOpen: () => setOpen(true),
      }}
      {...props}
    />
  )
}

type TriggerProps = ComponentProps<'button'> & {
  asChild?: boolean
}

const Trigger = ({
  onClick,
  asChild,
  children,
  className,
  ...props
}: TriggerProps) => {
  const { onOpen } = useModalContext()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    onOpen()
  }

  if (asChild && isValidElement(children)) {
    return cloneElement(children as ReactElement, {
      onClick: handleClick,
    })
  }

  return (
    <button
      {...props}
      onClick={handleClick}
      className={joinClassNames(styles.trigger, className)}
    >
      {children}
    </button>
  )
}

type CloseProps = ComponentProps<'button'> & {
  asChild?: boolean
}

const Close = ({
  onClick,
  asChild,
  children,
  className,
  ...props
}: CloseProps) => {
  const { onClose } = useModalContext()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    onClose()
  }

  if (asChild && isValidElement(children)) {
    return cloneElement(children as ReactElement, {
      onClick: handleClick,
    })
  }

  return (
    <button
      {...props}
      onClick={handleClick}
      className={joinClassNames(styles.close, className)}
    >
      {children}
    </button>
  )
}

interface ContentProps extends ComponentProps<'div'> {
  size?: 'sm' | 'md' | 'lg'
}

const Content = ({ className, size = 'sm', ...props }: ContentProps) => {
  const { isOpen } = useModalContext()

  return (
    isOpen &&
    ReactDOM.createPortal(
      <div className={styles.root}>
        <div
          {...props}
          className={[styles.content, styles[size], className].join(' ')}
        />
      </div>,
      document.body,
    )
  )
}

const Footer = ({ className, children, ...props }: ComponentProps<'div'>) => (
  <div {...props} className={joinClassNames(styles.footer, className)}>
    <hr />
    <div>{children}</div>
  </div>
)

const Title = ({ className, ...props }: ComponentProps<'div'>) => (
  <div {...props} className={joinClassNames(styles.title, className)} />
)

const Modal = {
  Root,
  Trigger,
  Close,
  Content,
  Footer,
  Title,
}

export default Modal
